<template>
  <div id="">
    <v-layout style="width:270vw; margin:auto; max-width:1050px">

    <v-card flat style="margin:auto"  @click.native="cardClicked(listings[el].url)" :href="listings[el].url" :key="listings[el].url" v-for="el in selection" class="pa-2" max-width="350" width="90vw">
      <v-img
        height='180'
        :src="'https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_180/v1539854631/'+listings[el].img"
        :href="listings[el].url"
      >
      </v-img>
      <v-rating

      v-model="listings[el].stars"
      background-color="primary"
      color="primary"
      readonly
      large
      ></v-rating>
      <v-card-text class="max-title-height align-center">
        <div class="block-with-text">
           {{listings[el].comment}}
        </div>
      </v-card-text>
      <v-card-text>
        <v-layout wrap>
          <v-flex xs3>
            <v-avatar
              color="primary"
            >
              <span class="white--text headline" v-if="listings[el].revPhoto==''">{{listings[el].revName[0]}}</span>
              <img :src="'https://res.cloudinary.com/my-room-abroad/image/upload/ar_1:1,c_fill,g_auto,f_auto,q_auto,h_100/v1539854631/'+listings[el].revPhoto" v-else >
            </v-avatar>
          </v-flex>
          <v-flex xs9 >
            <div class="py-1 subheading">
              {{listings[el].revName}}
            </div>
            <div class="">
              {{listings[el].revLoc}}
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>

  </v-layout>

  </div>
</template>
<script>
export default {
  name: "",
  props:['listings'],
  data: () => ({

  }),
  methods: {
    cardClicked(url) {
      dataLayer.push({
        'event':'reviewClicked',
        'url':url,
      })

    }
  },

  computed:{
    selection(){
      var arr = [];
      while(arr.length < 3){
          var r = Math.floor(Math.random() * this.listings.length)
          if(arr.indexOf(r) === -1) arr.push(r);
      }
      console.log(arr)
      return arr
    },
  },
}
</script>
<style lang="scss" scoped>
.max-title-height{
  height:5em;
}
.block-with-text {
  display: -webkit-box;
  max-width: 100%;
  height: 3.8em; /* Fallback for non-webkit */
  line-height: 1.3em;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
