<template>
  <div id="">
    <v-layout wrap pa-2>
      <v-flex xs12 class="pa-2">
        <h2>為甚麼你該選擇在My Room Abroad租房呢?</h2>
      </v-flex>
      <v-flex >
        <div class="noScrollbar" style="max-width:95vw; overflow-x: scroll;">
          <v-layout style="width:180vw; max-width:1050px; margin:auto">

          <v-card flat v-for="(vp, x) in vps" :key="'vp - '+x" style="margin:auto" class="pa-2" max-width="350px" width="60vw">
            <!-- <v-img
              class="ml-1"
              height='35'
              width='50'
              :src="'https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_25/v1539854631/'+vp.icon"
            >
            </v-img> -->
            <v-icon large color="primary">{{vp.icon}}</v-icon>
            <v-card-title class="pl-1">
                {{vp.title}}
            </v-card-title>
            <v-card-text class="pl-1">
              {{vp.text}}
            </v-card-text>
          </v-card>
        </v-layout>

        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  name: "",
  data: () => ({
    vps:[
      {
        icon:"forum",
        title:"每天都可以接觸英文",
        text:"你將很容易接觸外國室友，在生活會話中增強英文能力!",
      },
      {
        icon:"verified_user",
        title:"提前了解室友的國籍",
        text:"瀏覽1000+房源，並透過網站提前了解未來室友的國籍，享受文化交流!",
      },
      {
        icon:"language",
        title:"住在台北便能和世界接軌",
        text:" 透過My Room Abroad的社群，與來自20個國家的人交朋友!",
      },
    ]
  }),
}
</script>
<style lang="scss" scoped>
.noScrollbar::-webkit-scrollbar {
  display: none;
}
.noScrollbar{
  -ms-overflow-style:none;
}

</style>
