<template>
  <div class="toolbar">
    <v-toolbar flat class="pt-1 transparent">
      <!-- <v-spacer></v-spacer> -->
         <router-link to="/">
           <img src="@/assets/mainLogoWhite.svg" class="navbar-logo" alt="My Room Abroad logo">
         </router-link>
       <v-spacer></v-spacer>
       <v-btn color="white" small flat href="/login">log in</v-btn>

    </v-toolbar>
  </div>
</template>
<script>
export default {
  name: "",
  data: () => ({

  })
}
</script>
<style lang="scss" scoped>
.transparent a {
    color: #ffffff;
}
.transparent {
    color: #ffffff !important;
    border-color: white;
    box-shadow: none;
    .v-btn__content{
      color: #ffffff;
    }
    .v-btn--outline{
      border-color: #ffffff;
    }

}
img.navbar-logo {
    height: 30px;
}

</style>
