<template>
  <div id="">
    <v-layout wrap pa-2>
      <v-flex xs12 class="px-2">
        <h2>台北最棒的共享公寓!</h2>
      </v-flex>
      <v-flex xs12 class="px-2">
        我們在台北有超過2000個最棒的公寓等著你!
      </v-flex>
      <v-flex >
        <div class="noScrollbar" style="max-width:95vw; overflow-x: scroll;">
          <listingCards  :listings="rooms"></ListingCards>
        </div>

      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import ListingCards from './ListingCards.vue'
export default {
  name: "",
  data: () => ({
    url:'/aptSearch?propType=wholeApts',
    rooms:[
      {
        url:'/studioDetails/-LhiiKAPP_NSJSzwu-6_/',
        img:"-Lhii8L4OCPfmhHHLyLE/gx7voajrdfjnq777yeao",
        title:'Clean One-Bedroom Apartment Near Zhongxiao Dunhua',
        price:"NT$ 32000/月",
        area:"信義",
      },{
        url:'/apartmentDetails/-LQ7Zttpj4MSYG0kxtyv/apartment/',
        img:"-LOqEaHmz54W80lAcNlK/hbh1ndzyzhybma9mjvkd",
        title:'Fancy four-bedroom apartment in central Taipei',
        price:"NT$ 60000/月",
        area:"信義",
      },{
        url:'/apartmentDetails/-Lmd2bkT2d3t7N8pMhth/apartment/',
        img:"-LfqrFaK9uxRisxLq-Tv/tvqmatjym2zn4tvamxn9",
        title:'Beautiful apartment nearby Housanpi MRT Station',
        price:"NT$ 58000/月",
        area:"後山埤站",
      },{
        url:'/apartmentDetails/-LxeuaO3ueObPveu8UYs/apartment',
        img:"-LfqrFaK9uxRisxLq-Tv/ait2hk4tdgsaf7ubn4ss",
        title:'[Short term] Apartment nearby Taipower Building MRT',
        price:"NT$ 42560/月",
        area:"台電大樓站",
      },{
        url:'/apartmentDetails/-M-SeOoMVSoR83ZF7mxt/apartment',
        img:"-M-SdwHaet1l9J8dHBuc/iq62wsb2mwmfbuzn5ps5",
        title:'1 min to MRT Dapinglin Station/Elevator Apartment',
        price:"NT$ 46000/月",
        area:"大坪林站",
      },{
        url:'/apartmentDetails/-LzbTR1TowuCM8RbM3OL/apartment',
        img:"-LadCciJVw5eu48L33Kv/rjiryhfhethb2vm82fd1",
        title:"Very central 4 bedroom Apartment in Xiny Anhe",
        price:"NT$ 40500/月",
        area:"信義",
      },{
        url:'/apartmentDetails/-LX1yQ6qRtr4acMI3Vgb/apartment/',
        img:"-LOqmuL1fdmuyvZRaYZj/bazm76ncs8xojwfkacta",
        title:'3-Bedroom apartment close to MRT Taipower Building',
        price:"NT$ 40000/月",
        area:"台電大樓站",
      },{
        url:'/apartmentDetails/-LX1yQ6qRtr4acMI3Vgb/apartment/',
        img:"-LSTBDdvd2kH_lhBatad/ijrnqejbblo0cebwub03",
        title:'3-Bedroom Apartment close to Xinyi commercial center',
        price:"NT$ 35000/月",
        area:"信義",
      }
    ]
  }),
  methods: {
    showAllClick() {
      dataLayer.push({
        'event':'showAllClicked'
      })
    }
  },
  components: {
    ListingCards
  }
}
</script>
<style lang="scss" scoped>
.noScrollbar::-webkit-scrollbar {
  display: none;
}
.noScrollbar{
  -ms-overflow-style:none;
}

</style>
