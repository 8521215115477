<template>
  <div id="">
    <v-layout wrap pa-2>
      <v-flex xs12 class="px-2">
        <h2>來自台灣房客的感想</h2>
      </v-flex>
      <v-flex >
        <div class="noScrollbar" style="max-width:95vw; overflow-x: scroll;">
          <ReviewCards  :listings="rooms"></ReviewCards>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import ReviewCards from './ReviewCardsZH.vue'
export default {
  name: "",
  data: () => ({
    rooms:[
      {
        url:'/apartmentDetails/-Lfs0KpfdRaQhE0EmjGw/rooms/',
        img:"-Lfs-XAhAZbcQgXNbekF/c7dlaldoulvxshwribto",
        stars:5,
        comment:"這一年多來真的累積了許多很美好的回憶，謝謝你們的陪伴。",
        revName:"小偉",
        revOcc:"歲工程師",
        revLoc:"台北",
        revAge:"25",
      },
      {
        url:'/details/-LwpluVZ9KcazeRiqRsW',
        img:"-LfXbyi4E5HfERRvQ9JQ/zymtum1gpphotwsguzxi",
        stars:5,
        comment:"住在My Room Abroad 將近半年的時間，每天和室友們在一起都很開心，貼心的大家常常給我很多很多的照顧和溫暖 <3。最喜歡和大家一起聊天，悠哉在家的時光了。謝謝你們，我全世界最好的室友~期待不久後再見<3 LOVE YOU ALL~",
        revName:"Jenny",
        revOcc:"歲行銷專員",
        revLoc:"台中",
        revAge:"29",
      },
      {
        url:'/details/-LQODg8O1Qnc-nD0mqva/',
        img:"-LOu1ubbI4O3cvMSox2o/lrn76trynwjqi5uzrbgz",
        stars:5,
        comment:"在這邊真的體驗到了好多東西，謝謝你們每次的分享，最讓人珍惜的是認識了你們這群朋友，我真的很幸運。Keep in touch !",
        revName:"依柔",
        revOcc:"歲大學生",
        revLoc:"台南",
        revAge:"20",
      }
    ]
  }),

  components: {
    ReviewCards
  }
}
</script>
<style lang="scss" scoped>
.noScrollbar::-webkit-scrollbar {
  display: none;
}
.noScrollbar{
  -ms-overflow-style:none;
}

</style>
