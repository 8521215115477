<template>
  <div id="">
      <v-layout wrap class="max-size">
        <v-flex xs12 class="text-xs-right">
          <v-breadcrumbs divider="" justify-end >
            <v-icon slot="divider">chevron_right</v-icon>
            <v-breadcrumbs-item v-for="(item,n) in breadcrumbs" :key="n" :disabled="item.disabled">
              <a :href="item.href">{{item.text}}</a>
            </v-breadcrumbs-item>
          </v-breadcrumbs>

        </v-flex>
      </v-layout>
      <BestRooms class="max-size"></BestRooms>
      <v-divider class="max-size"></v-divider>
      <VP class="max-size"></VP>
      <v-divider class="max-size"></v-divider>
      <RoomsReview class="max-size"></RoomsReview>
      <Types class="max-size"></Types>
      <v-layout pa-2 class="max-size" wrap>
        <v-flex xs12>
          <h2>When are you coming to Taiwan?</h2>
        </v-flex>
        <v-flex xs12>
          <v-btn color="primary" @click="addDatesClick" href="#top">Add Dates</v-btn>
        </v-flex>
      </v-layout>
      <div class="imageBg">
        <v-divider class="hidden-sm-and-up mx-4"></v-divider>
        <v-layout  wrap id="top" class="spacingLeftLarge">
          <!-- <v-flex xs12> -->
            <SimpleSearch :pDateMenu="dateMenu" @changeDateMenu="dateMenu=$event" class="ma-2 pa-2" :title='title'></SimpleSearch>
          </v-flex>
        </v-layout>
      </div>

  </div>
</template>
<script>
import Navbar from '../components/LPNavbarWhite.vue'
import NavbarTransp from '../components/LPNavbarTransparent.vue'
import SimpleSearch from '../components/LPSimpleSearch.vue'
import RoomsReview from '../components/LPRoomsReview.vue'
import VP from '../components/LPVP.vue'
import BestRooms from '../components/LPBestRooms.vue'
import GenerateCoupon from '../components/LPCouponCreation.vue'
import Types from '../components/LPTypes.vue'

import AWSSES from '../../../aws-ses'
import moment from 'moment'
import axios from 'axios'

export default {
  name: "",
  data: () => ({
    title:'Find the best room in Taipei',
    dateMenu:false,
    breadcrumbs:[
      {
        text: 'Taiwan',
        disabled: false,
        href: '/Taiwan',
      },
      {
        text: 'Taipei',
        disabled: false,
        href: '/Taiwan/Taipei',
      },{
        text: 'Rooms for rent in Taipei',
        disabled: true,
      },
    ],

  }),
  methods: {
    addDatesClick(){
      this.dateMenu=true
      dataLayer.push({
        'event':'addDatesClicked',
      })
    }
  },
  components: {
    Navbar,
    NavbarTransp,
    SimpleSearch,
    RoomsReview,
    VP,
    BestRooms,
    Types,
  },
  metaInfo() {
    return {
      title: 'Rooms for rent in Taipei on My Room Abroad - Housing Website', // set a title
      titleTemplate: '%s', // title is now "My Example App - Yay!"
      meta:[
        { vmid:'identifier-url', name:'identifier-url',content:'https://myroomabroad.com'+this.$route.path},
        { vmid:'description', name:'description', content:"Easily find THE room you are looking for in Taipei on My Room Abroad. Book now and get the best prices on My Room Abroad"},
        {vmid:'og:description',property:'og:description',content:"Ealsily find THE room you are looking for in Taipei on My Room Abroad. Book now and get the best prices on My Room Abroad"},
        {vmid:'og:image', property:'og:image', content:'https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,w_1200/c_crop,h_630,w_1200,q_auto,e_sharpen/g_south_east,l_Logo:mra-watermark-logo,x_50,y_50/v1581056050/homepage/taipei_sunset.jpg'},
        {vmid:'og:image:type', property:'og:image:type',content:'image/jpg'},
        {vmid:'og:image:width', property:'og:image:width',content:'1200'},
        {vmid:'og:image:height', property:'og:image:height',content:'630'},
      ]
    }
  },
}

</script>
<style lang="scss" scoped>
  @media only screen and (min-width:1070px){
    .max-size{
      width:1070px;
      margin:auto;
    }
  }
  @media only screen and (min-width:600px){
    .spacingLeftLarge{
      margin-left: 10vw;
      padding-top:5vh;
      padding-bottom:5vh;
    }
    .imageBg {
      color:white;
      background: rgba(255,255,255,0),;
      background: rgba(102,102,102,0.5), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_fill,q_auto,w_2880,e_sharpen/v1581056050/homepage/taipei_sunset.jpg") center/cover no-repeat;
      background: -moz-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_fill,q_auto,w_2880,e_sharpen/v1581056050/homepage/taipei_sunset.jpg") center/cover no-repeat;
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(102,102,102,0.5)), color-stop(40%, rgba(133,133,133,0)), color-stop(100%, rgba(255,255,255,0))), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_fill,q_auto,w_2880,e_sharpen/v1581056050/homepage/taipei_sunset.jpg") center/cover no-repeat;
      background: -webkit-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_fill,q_auto,w_2880,e_sharpen/v1581056050/homepage/taipei_sunset.jpg") center/cover no-repeat;
      background: -o-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_fill,q_auto,w_2880,e_sharpen/v1581056050/homepage/taipei_sunset.jpg") center/cover no-repeat;
      background: -ms-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_fill,q_auto,w_2880,e_sharpen/v1581056050/homepage/taipei_sunset.jpg") center/cover no-repeat;
      background: linear-gradient(to bottom, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_fill,q_auto,w_2880,e_sharpen/v1581056050/homepage/taipei_sunset.jpg") center/cover no-repeat;
    }

  }
</style>
