<template>
  <div id="">
    <v-layout wrap pa-2>
      <v-flex xs12 class="px-2">
        <h2>Some of the best studios in Taipei</h2>
      </v-flex>
      <v-flex xs12 class="px-2">
        We have listed over 2000 of the best studios available in Taipei.
      </v-flex>
      <v-flex >
        <div class="noScrollbar" style="max-width:95vw; overflow-x: scroll;">
          <listingCards  :listings="rooms"></ListingCards>
        </div>
        <v-layout pa-3>
          <a color="primary" class="subheading" @click="showAllClick()" :href="url">Show All (2000+)</a>
        </v-layout>

      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import ListingCards from './ListingCards.vue'
export default {
  name: "",
  data: () => ({
    url:'/search?propType=studio',
    rooms:[
      {
        url:'/studioDetails/-LTtjyMusXMA0_tai0MO/',
        img:"-LOu1ubbI4O3cvMSox2o/gv9ujn9gfdg3i9qgj5if",
        title:'Ximen MRT Stylish Studio',
        price:"TW$ 23000/Month",
        area:"Ximen",
        nextAv:"March 1st"
      },{
        url:'/studioDetails/-LW-vntFXkrIPIm81U6A/',
        img:"-LVbNnvzMFf2fOojbxl_/mv8qbls0d1wxfg9y9cie",
        title:'Small Studio Apartment near Gongguan MRT Station',
        price:"TW$ 27300/Month",
        area:"Gongguan",
        nextAv:"March 1st"
      },{
        url:'/studioDetails/-LfFXdeAVDzzMnfuTlZd/',
        img:"-Lf4pMi7QapdpRacC7XJ/tihodgd2kirmpfyqh93c",
        title:'Charming Studio Close to NTNU',
        price:"TW$ 23000/Month",
        area:"NTNU",
        nextAv:"March 1st"
      },{
        url:'/studioDetails/-Luq6ry5KYLtkzeGn0pS',
        img:"-Ls6C240wY7HZbQu9g42/apizwspoiqopvcgtlfi7",
        title:'Lovely Studio near NTNU and Taipower building MRT',
        price:"TW$ 18993/Month",
        area:"Taipower Building",
        nextAv:"March 1st"
      },{
        url:'/studioDetails/-L_fwXU9vhU0GNHRENwq/',
        img:"-LZdD3sWOl9XfdPLOQul/yxuapel3cpvfrfflq56r",
        title:'Newly Furnished Studio with Kitchen',
        price:"TW$ 18200/Month",
        area:"Da'an",
        nextAv:"March 1st"
      },{
        url:'/studioDetails/-LjUuEOFTc3wvl7d5oEX/',
        img:"-LfqrFaK9uxRisxLq-Tv/cin9hmqa2kfs7uly0dfa",
        title:"Cozy Studio Near Da'an MRT station",
        price:"TW$ 26000/Month",
        area:"Da'an",
        nextAv:"March 1st"
      },{
        url:'/studioDetails/-LqFuaw6B2l9i4ljpr-z/',
        img:"-Leo36rw_9jpj0wgyJT-/maf6xfnvr3mypeij9yo8",
        title:'*Monthly Rental* cozy studio in Daan',
        price:"TW$ 15000/Month",
        area:"Da'an",
        nextAv:"March 1st"
      },{
        url:'/studioDetails/-LW0QbHmOaWVLAG79GPc/',
        img:"-LVbNnvzMFf2fOojbxl_/u0wfwmriknwvepdq1eol",
        title:'Clean studio Near Gongguan MRT station',
        price:"TW$ 25200/Month",
        area:"Gongguan",
        nextAv:"March 1st"
      },{
        url:'/studioDetails/-LVGyJFSzenY52Zn7Ytn/',
        img:"-LSTBDdvd2kH_lhBatad/dl5oqkhmiby4n1ydt0ue",
        title:'Bright Studio near Zhongshan Elementary School MRT',
        price:"TW$ 25800/Month",
        area:"Zhongshan",
        nextAv:"March 1st"
      },{
        url:'/studioDetails/-LfxSWXtAIzDJ6DBQIDy/',
        img:"-LVbNnvzMFf2fOojbxl_/tx5cddw4jcj9ntzogxrd",
        title:'Studio apartment near Xingtian Temple MRT Station',
        price:"TW$ 47250/Month",
        area:"Zhongshan",
        nextAv:"March 1st"
      },{
        url:'/studioDetails/-LTu2qIeMYh8sqArluDV/',
        img:"-LTWXmAuaRpPZQ5DFWPE/uhsoj2dsnsbwqfb2afbc",
        title:'New York themed Studio Near beimen Station',
        price:"TW$ 22700/Month",
        area:"Beimen",
        nextAv:"March 1st"
      }
    ]
  }),
  methods: {
    showAllClick() {
      dataLayer.push({
        'event':'showAllClicked'
      })
    }
  },
  components: {
    ListingCards
  }
}
</script>
<style lang="scss" scoped>
.noScrollbar::-webkit-scrollbar {
  display: none;
}
.noScrollbar{
  -ms-overflow-style:none;
}

</style>
