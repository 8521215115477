<template>
  <div id="">
    <v-layout wrap pa-2>
      <v-flex xs12 class="px-2">
        <h2>Some of the best accommodation in Taipei</h2>
      </v-flex>
      <v-flex xs12 class="px-2">
        We have listed over 2000 of the best accommodation available in Taipei.
      </v-flex>
      <v-flex >
        <div class="noScrollbar" style="max-width:95vw; overflow-x: scroll;">
          <listingCards  :listings="rooms"></ListingCards>
        </div>
        <v-layout pa-3>
          <a color="primary" class="subheading" @click="showAllClick()" :href="url">Show All (2000+)</a>
        </v-layout>

      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import ListingCards from './ListingCards.vue'
export default {
  name: "",
  data: () => ({
    url:'/AptSearch',
    rooms:[
      {
        url:'/studioDetails/-LhiiKAPP_NSJSzwu-6_/',
        img:"-Lhii8L4OCPfmhHHLyLE/gx7voajrdfjnq777yeao",
        title:'Clean One-Bedroom Apartment Near Zhongxiao Dunhua',
        price:"TW$ 32000/Month",
        area:"Xinyi",
        nextAv:"March 1st"
      },{
        url:'/apartmentDetails/-LQ7Zttpj4MSYG0kxtyv/apartment/',
        img:"-LOqEaHmz54W80lAcNlK/hbh1ndzyzhybma9mjvkd",
        title:'Fancy four-bedroom apartment in central Taipei',
        price:"TW$ 60000/Month",
        area:"Xinyi",
        nextAv:"March 1st"
      },{
        url:'/apartmentDetails/-Lmd2bkT2d3t7N8pMhth/apartment/',
        img:"-LfqrFaK9uxRisxLq-Tv/tvqmatjym2zn4tvamxn9",
        title:'Beautiful apartment nearby Housanpi MRT Station',
        price:"TW$ 58000/Month",
        area:"Housanpi",
        nextAv:"March 1st"
      },{
        url:'/apartmentDetails/-LxeuaO3ueObPveu8UYs/apartment',
        img:"-LfqrFaK9uxRisxLq-Tv/ait2hk4tdgsaf7ubn4ss",
        title:'[Short term] Apartment nearby Taipower Building MRT',
        price:"TW$ 42560/Month",
        area:"Taipower Building",
        nextAv:"March 1st"
      },{
        url:'/apartmentDetails/-M-SeOoMVSoR83ZF7mxt/apartment',
        img:"-M-SdwHaet1l9J8dHBuc/iq62wsb2mwmfbuzn5ps5",
        title:'1 min to MRT Dapinglin Station/Elevator Apartment',
        price:"TW$ 46000/Month",
        area:"Dapinglin",
        nextAv:"March 1st"
      },{
        url:'/apartmentDetails/-LzbTR1TowuCM8RbM3OL/apartment',
        img:"-LadCciJVw5eu48L33Kv/rjiryhfhethb2vm82fd1",
        title:"Very central 4 bedroom Apartment in Xiny Anhe",
        price:"TW$ 40500/Month",
        area:"Xinyi",
        nextAv:"March 1st"
      },{
        url:'/apartmentDetails/-LX1yQ6qRtr4acMI3Vgb/apartment/',
        img:"-LOqmuL1fdmuyvZRaYZj/bazm76ncs8xojwfkacta",
        title:'3-Bedroom apartment close to MRT Taipower Building',
        price:"TW$ 40000/Month",
        area:"Taipower Building",
        nextAv:"March 1st"
      },{
        url:'/apartmentDetails/-LX1yQ6qRtr4acMI3Vgb/apartment/',
        img:"-LSTBDdvd2kH_lhBatad/ijrnqejbblo0cebwub03",
        title:'3-Bedroom Apartment close to Xinyi commercial center',
        price:"TW$ 35000/Month",
        area:"Xinyi",
        nextAv:"March 1st"
      },
      // ROOMS

        {
          url:'/details/-LQOAdyVkvXrqpK5OoX5/',
          img:"-LOu1ubbI4O3cvMSox2o/azjhon2ixerxd3zccjbg",
          title:'Stylish elevator room @Gongguan MRT',
          price:"TW$ 14000/Month",
          area:"Gongguan",
          nextAv:"March 1st"
        },{
          url:'/details/-LSxJf_ZY08sSArBUUVH/',
          img:"-LSOHT4wFtvKabn9ZQTk/ukgvactogmfj2cqeuva5",
          title:'Beautiful room with private bathroom in Ximen',
          price:"TW$ 16500/Month",
          area:"Ximen",
          nextAv:"March 1st"
        },{
          url:'/details/-LeWEqOJV2CdAyWUXX8d/',
          img:"-LePHAxaYK7ieH64tEe1/gkl7vwgxq8xppuajwdaj",
          title:'Delicate & Cosy room',
          price:"TW$ 10000/Month",
          area:"Da'an",
          nextAv:"March 1st"
        },{
          url:'/details/-LYAhpVcbt0k5ybqITEC/',
          img:"-LOqmuL1fdmuyvZRaYZj/khyvfrdhvndt3fe3vqyd",
          title:'Bright Double Bedroom with view on NTNU MTC',
          price:"TW$ 10000/Month",
          area:"Da'an",
          nextAv:"March 1st"
        },{
          url:'/details/-LRlNN5KPXgEOgFLmLaZ/',
          img:"-LR6Gn5fs7LgX_DNB9BT/cznsrnaiidrsrexttnlc",
          title:'Industrial design Single bedroom',
          price:"TW$ 16000/Month",
          area:"Da'an",
          nextAv:"March 1st"
        },{
          url:'/details/-LQgpqO3U8bLs7Bks_oQ/',
          img:"-LOufGM14aDe3Q8faEOl/fp2hyra8i6suuwkbaub5",
          title:"Spacious Ensuite Bedroom near Da'an ",
          price:"TW$ 18000/Month",
          area:"Da'an",
          nextAv:"March 1st"
        },{
          url:'/details/-LQbatskIMnqpBTdwPxt/',
          img:"-LOu1ubbI4O3cvMSox2o/h2pmphhpsiwihyw7qgjy",
          title:'Stylish room near NTU NTUST',
          price:"TW$ 17500/Month",
          area:"Guting",
          nextAv:"March 1st"
        },{
          url:'/details/-LQI9GJKSGfLyrToPi9w/',
          img:"-LOu1ubbI4O3cvMSox2o/qvgh7cn9guyzphz0ro0q",
          title:'NTNU stylish studio @ Taipower Building MRT 5 min',
          price:"TW$ 18500/Month",
          area:"Da'an",
          nextAv:"March 1st"
        },{
          url:'/details/-LkLnVINW47evSaC4BW1/',
          img:"-LOu1ubbI4O3cvMSox2o/wer9lsk6ihmbkhbnk1yv",
          title:'Stylish room near NTU NTUST @Technology building MRT',
          price:"TW$ 12500/Month",
          area:"Da'an",
          nextAv:"March 1st"
        },
      //STUDIOS

        {
          url:'/studioDetails/-LTtjyMusXMA0_tai0MO/',
          img:"-LOu1ubbI4O3cvMSox2o/gv9ujn9gfdg3i9qgj5if",
          title:'Ximen MRT Stylish Studio',
          price:"TW$ 23000/Month",
          area:"Ximen",
          nextAv:"March 1st"
        },{
          url:'/studioDetails/-LW-vntFXkrIPIm81U6A/',
          img:"-LVbNnvzMFf2fOojbxl_/mv8qbls0d1wxfg9y9cie",
          title:'Small Studio Apartment near Gongguan MRT Station',
          price:"TW$ 27300/Month",
          area:"Gongguan",
          nextAv:"March 1st"
        },{
          url:'/studioDetails/-LfFXdeAVDzzMnfuTlZd/',
          img:"-Lf4pMi7QapdpRacC7XJ/tihodgd2kirmpfyqh93c",
          title:'Charming Studio Close to NTNU',
          price:"TW$ 23000/Month",
          area:"NTNU",
          nextAv:"March 1st"
        },{
          url:'/studioDetails/-Luq6ry5KYLtkzeGn0pS',
          img:"-Ls6C240wY7HZbQu9g42/apizwspoiqopvcgtlfi7",
          title:'Lovely Studio near NTNU and Taipower building MRT',
          price:"TW$ 18993/Month",
          area:"Taipower Building",
          nextAv:"March 1st"
        },{
          url:'/studioDetails/-L_fwXU9vhU0GNHRENwq/',
          img:"-LZdD3sWOl9XfdPLOQul/yxuapel3cpvfrfflq56r",
          title:'Newly Furnished Studio with Kitchen',
          price:"TW$ 18200/Month",
          area:"Da'an",
          nextAv:"March 1st"
        },{
          url:'/studioDetails/-LjUuEOFTc3wvl7d5oEX/',
          img:"-LfqrFaK9uxRisxLq-Tv/cin9hmqa2kfs7uly0dfa",
          title:"Cozy Studio Near Da'an MRT station",
          price:"TW$ 26000/Month",
          area:"Da'an",
          nextAv:"March 1st"
        },{
          url:'/studioDetails/-LqFuaw6B2l9i4ljpr-z/',
          img:"-Leo36rw_9jpj0wgyJT-/maf6xfnvr3mypeij9yo8",
          title:'*Monthly Rental* cozy studio in Daan',
          price:"TW$ 15000/Month",
          area:"Da'an",
          nextAv:"March 1st"
        },{
          url:'/studioDetails/-LW0QbHmOaWVLAG79GPc/',
          img:"-LVbNnvzMFf2fOojbxl_/u0wfwmriknwvepdq1eol",
          title:'Clean studio Near Gongguan MRT station',
          price:"TW$ 25200/Month",
          area:"Gongguan",
          nextAv:"March 1st"
        },{
          url:'/studioDetails/-LVGyJFSzenY52Zn7Ytn/',
          img:"-LSTBDdvd2kH_lhBatad/dl5oqkhmiby4n1ydt0ue",
          title:'Bright Studio near Zhongshan Elementary School MRT',
          price:"TW$ 25800/Month",
          area:"Zhongshan",
          nextAv:"March 1st"
        },{
          url:'/studioDetails/-LfxSWXtAIzDJ6DBQIDy/',
          img:"-LVbNnvzMFf2fOojbxl_/tx5cddw4jcj9ntzogxrd",
          title:'Studio apartment near Xingtian Temple MRT Station',
          price:"TW$ 47250/Month",
          area:"Zhongshan",
          nextAv:"March 1st"
        },{
          url:'/studioDetails/-LTu2qIeMYh8sqArluDV/',
          img:"-LTWXmAuaRpPZQ5DFWPE/uhsoj2dsnsbwqfb2afbc",
          title:'New York themed Studio Near beimen Station',
          price:"TW$ 22700/Month",
          area:"Beimen",
          nextAv:"March 1st"
        }
    ]
  }),
  methods: {
    showAllClick() {
      dataLayer.push({
        'event':'showAllClicked'
      })
    }
  },
  components: {
    ListingCards
  }
}
</script>
<style lang="scss" scoped>
.noScrollbar::-webkit-scrollbar {
  display: none;
}
.noScrollbar{
  -ms-overflow-style:none;
}

</style>
