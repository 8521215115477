import { render, staticRenderFns } from "./TaiwanZH2.vue?vue&type=template&id=1ab6f112&scoped=true&"
import script from "./TaiwanZH2.vue?vue&type=script&lang=js&"
export * from "./TaiwanZH2.vue?vue&type=script&lang=js&"
import style0 from "./TaiwanZH2.vue?vue&type=style&index=0&id=1ab6f112&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ab6f112",
  null
  
)

export default component.exports