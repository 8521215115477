<template>
  <div id="">
    <v-card flat class="sizes">
      <v-card-title primary-title>
        <h1 class="headline" >{{title}}</h1>
      </v-card-title>
      <v-card-text>
        <v-layout wrap>
          <v-flex xs12>
            <v-text-field
            v-model="userName"
            label="Username"
            :error-messages="userNameErrorMessage"
            @blur="$v.userName.$touch()"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
            v-model="email"
            label="Email"
            :error-messages="emailErrorMessage"
            @blur="$v.email.$touch()"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
            v-model="pw"
            type="password"
            label="Password"
            :error-messages="pwErrorMessage"
            @blur="$v.pw.$touch()"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-checkbox v-model="terms" hide-details :error-messages="termsError">
                <span slot="label">I agree to the <a class="link" href="/policies/terms" target="_blank">Terms and Conditions</a></span>
            </v-checkbox>

          </v-flex>
        </v-layout>
      </v-card-text>

      <v-card-actions>
        <v-flex xs12 class="text-xs-right">
          <v-btn color="primary" :disabled="sending"  @click="submit()">SUBMIT</v-btn>
        </v-flex>

      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { required, email, minLength} from 'vuelidate/lib/validators'
import axios from 'axios'
import axiosAuth from '../../../axios-auth'
import AWSSES from '../../../aws-ses'
import config from '../../../config.js'
import {
  mapActions
} from 'vuex'
import * as types from '../../../store/types'


export default {
  name: "",
  props:['title'],
  data: () => ({
    userName:"",
    email:'',
    pw:'',
    terms:false,
    sending:false,
    emailNotUnique:false
  }),
  methods: {
    ...mapActions({
      signUpAction: types.SIGN_UP
    }),
    submit() {
      this.sending=true
      this.emailNotUnique=false
      this.$v.$touch()
      if (this.$v.$error || this.$v.$invalid) {
        this.sending=false
        return
      } else {
        axios.get('/users.json?orderBy="email"&equalTo="' + this.email + '"')
        .then(res => {
          if (Object.keys(res.data).length != 0) {
            console.log('email error');
            this.emailNotUnique=true
            this.sending=false
            return
          } else {
            console.log('all fine');
            const userData = {
              userName: this.userName,
              email: this.email,
              password: this.pw,
              keepLogged: false,
              roleValue: '10',
            }
            console.log(userData);
            axiosAuth.post('/signupNewUser?key='+config.FIREBASE_API_KEY, {
              email: userData.email,
              password: userData.password,
              returnSecureToken: true
            }).then(res => {
              console.log(res);
              // AWSSES.emailing(userData, 'TSignUp')
              let dataToSend=res.data
              let newData={
                userId:dataToSend.localId,
                localId:dataToSend.localId,
                userName: this.userName,
                email: this.email,
                roleValue: '10',
                keepLogged:false,
                dateAdded:new Date()
              }
              try {
                axios.post('/users.json' + '?auth=' + dataToSend.idToken , newData)
                .then(()=>{
                  console.log('success');
                  dataLayer.push({
                    'event':'lpSignUp'
                  })
                  this.$emit('signUpSuccess',{email:this.email})
                })
                .catch(err=>{
                  console.alert(err);
                })
              } catch (e) {
                console.log(e);
              }
            })
            .catch(err=>{
              console.log(err)
              this.sending=false
              alert('Sign up failed. Please try again')
            })
          }

        })
        .catch(err=>{
          console.log('error: ',err);
        })

      }

    }
  },
  computed:{
    userNameErrorMessage(){
      let errors=[]
      if (!this.$v.userName.$dirty) return errors
      !this.$v.userName.required && errors.push('userName is a required field')
      return errors
    },
    emailErrorMessage(){
      let errors=[]
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Please provide a valid email')
      !this.$v.email.required && errors.push('Email is a required field')
      this.emailNotUnique && errors.push('This email already exists')
      return errors
    },
    pwErrorMessage(){
      let errors=[]
      if (!this.$v.pw.$dirty) return errors
      !this.$v.pw.minLength && errors.push('Password needs to be at least 6 characters')
      !this.$v.pw.required && errors.push('Password is a required field')
      return errors
    },
    termsError(){
      let errors=[]
      if (!this.$v.terms.$dirty) return errors
      !this.$v.terms.checked && errors.push('Please accept our Terms and Conditions')
      return errors
    }
  },
  validations:{
    userName:{
      required
    },
    email:{
      required,
      email
    },
    pw:{
      required,
      minLength:minLength(6)
    },
    terms:{
      checked: value => value==true
    },
  }

}
</script>
<style lang="scss" scoped>
@media only screen and (min-width:600px){
  .sizes{
    width:35vw;
  }
}
@media only screen and (min-width:600px){
  .sizes{
    width:35vw;
  }
}

</style>
