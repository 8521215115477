<template>
  <div id="">
    <v-layout wrap pa-2>
      <v-flex xs12 class="pa-2">
        <h2>為甚麼你該選擇在My Room Abroad租房呢?</h2>
      </v-flex>
      <v-flex >
        <div class="noScrollbar" style="max-width:95vw; overflow-x: scroll;">
          <v-layout style="width:180vw; max-width:1050px; margin:auto">

          <v-card flat v-for="(vp, x) in vps" :key="'vp - '+x" style="margin:auto" class="pa-2" max-width="350px" width="60vw">
            <!-- <v-img
              class="ml-1"
              height='35'
              width='50'
              :src="'https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_25/v1539854631/'+vp.icon"
            >
            </v-img> -->
            <v-icon large color="primary">{{vp.icon}}</v-icon>
            <v-card-title class="pl-1">
                {{vp.title}}
            </v-card-title>
            <v-card-text class="pl-1">
              {{vp.text}}
            </v-card-text>
          </v-card>
        </v-layout>

        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  name: "",
  data: () => ({
    vps:[
      {
        icon:"event_available",
        title:"網站上1-12個月的彈性租期",
        text:"無論你想租多久，都會有適合你的房間",
      },
      {
        icon:"how_to_reg",
        title:"省下跟房東詢問、協商短租的時間",
        text:"網站上一目了然每間房的最短可接受租期，因此你不用花很多時間找外面願意短租的房東，並且My Room Abroad有最好的團隊協助你找到適合的房間",
      },
      {
        icon:"date_range",
        title:"入住時間彈性，你可以立即搬入，也可預定幾個月後的房間",
        text:"有些房間即刻便能入住，也有房間幾個月後會空出(可以提前訂房)。提早做準備，根據自己的需求、預定起租日期找到最適合你的房間!",
      },
    ]
  }),
}
</script>
<style lang="scss" scoped>
.noScrollbar::-webkit-scrollbar {
  display: none;
}
.noScrollbar{
  -ms-overflow-style:none;
}

</style>
