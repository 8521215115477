<template>
  <div id="">
    <div class="imageBg">

      <Navbar class="hidden-sm-and-up"></Navbar>
      <NavbarTransp class="hidden-xs-only"></NavbarTransp>
      <v-divider class="hidden-sm-and-up mx-4"></v-divider>
      <v-layout  wrap id="top" class="spacingLeftLarge">
        <!-- <v-flex xs12> -->
          <SimpleSearch :pDateMenu="dateMenu" @changeDateMenu="dateMenu=$event" class="ma-2 pa-2" :title='title'></SimpleSearch>
        </v-flex>
      </v-layout>
    </div>
    <v-divider class="hidden-sm-and-up mx-4"></v-divider>
      <v-layout wrap class="max-size">
        <v-flex xs12 class="text-xs-right">
          <v-breadcrumbs divider="" justify-end >
            <v-icon slot="divider">chevron_right</v-icon>
            <v-breadcrumbs-item v-for="(item,n) in breadcrumbs" :key="n" :disabled="item.disabled">
              <a :href="item.href">{{item.text}}</a>
            </v-breadcrumbs-item>
          </v-breadcrumbs>

        </v-flex>
      </v-layout>
      <v-layout wrap class="max-size pa-2">
        <v-flex xs12 class="pa-2">
          <h2>在台北、台中也能輕鬆找到一兩個月或是半年的短租！</h2>
        </v-flex>
        <v-flex xs12 sm5 class="pa-2">
          <v-img src="https://res.cloudinary.com/my-room-abroad/image/fetch/w_1400,q_auto,f_auto/https://res.cloudinary.com/my-room-abroad/image/upload/v1537411387/homepage/team2.jpg"></v-img>
        </v-flex>
        <v-flex xs12 sm7 class="ma-auto pa-2">
          <span>短期實習、工作的好選擇，彈性租約，短期租客不用再擔心找不到下一位租客而被扣押金！My Room Abroad幫你找到了台北、台中各地區願意接受短期租約的房東們，為你省下日租30天的昂貴費用。 想租多久，我們都可以幫你和房東溝通，免除你不必要的壓力。讓只是在台北、台中短期停留的你，輕鬆住在熱鬧方便的市中心！</span>
        </v-flex>
      </v-layout>
      <v-divider class="max-size"></v-divider>
      <VP class="max-size"></VP>
      <v-divider class="max-size"></v-divider>
      <BestApts class="max-size"></BestApts>
      <v-divider class="max-size"></v-divider>
      <AptsReview class="max-size"></AptsReview>
      <v-divider class="max-size"></v-divider>
      <Types class="max-size"></Types>
  </div>
</template>
<script>
import Navbar from '../components/LPNavbarWhite.vue'
import NavbarTransp from '../components/LPNavbarTransparent.vue'
import SimpleSearch from '../components/LPSimpleSearchZH.vue'
import AptsReview from '../components/LPListingsReviewZH2.vue'
import VP from '../components/LPVPZH2.vue'
import BestApts from '../components/LPBestListsZH2.vue'
import Types from '../components/LPTypesZH2.vue'

import AWSSES from '../../../aws-ses'
import moment from 'moment'
import axios from 'axios'

export default {
  name: "",
  data: () => ({
    title:'My Room Abroad網站上的房間可長租可短租，一個月以上便能起租!',
    dateMenu:false,
    breadcrumbs:[
      {
        text: '台灣',
        disabled: false,
        href: '/Taiwan',
      },
      {
        text: '台北',
        disabled: false,
        href: '/Taiwan/Taipei',
      },{
        text: '台北的公寓出租',
        disabled: true,
      },
    ],
  }),
  methods: {
    addDatesClick(){
      this.dateMenu=true
      dataLayer.push({
        'event':'addDatesClicked',
      })
    },
  },
  components: {
    Navbar,
    NavbarTransp,
    SimpleSearch,
    AptsReview,
    VP,
    BestApts,
    Types,
  },
  metaInfo() {
    return {
      title: '在台灣就可以進行國際化的交流！在台北租房，跟外國朋友當室友！', // set a title
      titleTemplate: '%s - My Room Abroad', // title is now "My Example App - Yay!"
      meta:[
        { vmid:'identifier-url', name:'identifier-url',content:'https://myroomabroad.com'+this.$route.path},
        { vmid:'description', name:'description', content:"Find THE apartment you are looking for on My Room Abroad. Book now and get the best prices on My Room Abroad"},
        {vmid:'og:description',property:'og:description',content:"Find THE apartment you are looking for on My Room Abroad. Book now and get the best prices on My Room Abroad"},
        {vmid:'og:image', property:'og:image', content:'https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,w_1200/c_crop,h_630,w_1200,q_auto,e_sharpen/g_south_east,l_Logo:mra-watermark-logo,x_50,y_50/v1581056050/homepage/taipei_sunset.jpg'},
        {vmid:'og:image:type', property:'og:image:type',content:'image/jpg'},
        {vmid:'og:image:width', property:'og:image:width',content:'1200'},
        {vmid:'og:image:height', property:'og:image:height',content:'630'},
      ]
    }
  },
}

</script>
<style lang="scss" scoped>
  @media only screen and (min-width:1070px){
    .max-size{
      width:1070px;
      margin:auto;
    }
  }
  @media only screen and (min-width:600px){
    .spacingLeftLarge{
      margin-left: 10vw;
      padding-top:5vh;
      padding-bottom:5vh;
    }
    .imageBg {
      color:white;
      background: rgba(255,255,255,0),;
      background: rgba(102,102,102,0.5), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_fill,q_auto,w_2880,e_sharpen/v1581056050/homepage/taipei_sunset.jpg") center/cover no-repeat;
      background: -moz-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_fill,q_auto,w_2880,e_sharpen/v1581056050/homepage/taipei_sunset.jpg") center/cover no-repeat;
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(102,102,102,0.5)), color-stop(40%, rgba(133,133,133,0)), color-stop(100%, rgba(255,255,255,0))), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_fill,q_auto,w_2880,e_sharpen/v1581056050/homepage/taipei_sunset.jpg") center/cover no-repeat;
      background: -webkit-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_fill,q_auto,w_2880,e_sharpen/v1581056050/homepage/taipei_sunset.jpg") center/cover no-repeat;
      background: -o-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_fill,q_auto,w_2880,e_sharpen/v1581056050/homepage/taipei_sunset.jpg") center/cover no-repeat;
      background: -ms-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_fill,q_auto,w_2880,e_sharpen/v1581056050/homepage/taipei_sunset.jpg") center/cover no-repeat;
      background: linear-gradient(to bottom, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_fill,q_auto,w_2880,e_sharpen/v1581056050/homepage/taipei_sunset.jpg") center/cover no-repeat;
    }

  }
</style>
