<template>
  <div class="toolbar">
    <v-toolbar flat class="pt-1 navbar">
       <router-link class="text-xs-center" to="/">
         <img src="@/assets/mainLogo.svg" class="navbar-logo" alt="My Room Abroad Logo"></img>
       </router-link>
       <v-spacer></v-spacer>
       <v-btn small flat :href="'/login'">log in</v-btn>
    </v-toolbar>
  </div>
</template>
<script>
export default {
  name: "",
  data: () => ({

  })
}
</script>
<style lang="scss" scoped>
img.navbar-logo {
    height: 25px;
    // padding-top:2px
}
.navbar {
    background-color: #ffffff;
}


</style>
