<template>
  <div id="">
    <v-layout wrap pa-2>
      <v-flex xs12 class="pa-2">
        <h2>Search for any type of listing</h2>
      </v-flex>
      <v-flex xs12 class="pa-2">
        We have listings of all types available just for you, rooms in shared apartments, private studios or even whole apartments.
      </v-flex>
      <v-flex >
        <div class="noScrollbar" style="max-width:95vw; overflow-x: scroll;">
          <v-layout style="width:180vw; max-width:1050px; margin:auto">

            <v-card flat v-for="(item, index) in rooms" :key="'type - '+index" style="margin:auto" class="pa-2" max-width="350px" width="80vw" @click.native="clickType" :href='item.link'>
              <v-img
                cover
                aspect-ratio="1.78"
                :src="item.photos[randomized[index]]"
              >
              </v-img>
              <v-card-title primary-title>
                {{item.title}}
              </v-card-title>
            </v-card>
          </v-layout>

        </div>
      </v-flex>
    </v-layout>


  </div>
</template>
<script>
export default {
  name: "",
  data: () => ({
    rooms:[{
      title:"Rooms",
      photos:["https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-LOufGM14aDe3Q8faEOl/bjzjr0rswdmm5qksjksf",
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-Lfs-XAhAZbcQgXNbekF/rhnfmwhiwnzxxbbv4six",
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-LOu1ubbI4O3cvMSox2o/caftjwr8dvvd2go1sxjv",
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-Lf4GXbYun4_nuus9M10/ldrvigvwufhnux21rvwv",
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-LOu1ubbI4O3cvMSox2o/jwuse5wnmwr3x6p2x3in"
      ],
      link: "/search?propType=room"
    },{
      title:"Studios",
      photos:[
        "https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,h_333,f_auto,q_auto/v1539854631/-LRzd0TKrgheb00DLZ6X/i3h9bebik8xq8rwdikii",
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-Lf4pMi7QapdpRacC7XJ/tihodgd2kirmpfyqh93c",
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-LOqFfd0QOKVbDjOMKUq/qosnheftf6iouusznbty",
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-Lhii8L4OCPfmhHHLyLE/eklyabxwtfaq3dux03od",
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-LhEqvKARGfCwW1FLcTc/iz16rykehnjf5pmglr9r"
      ],
      link: "/search?propType=studio"
    },{
      title:"Apartments",
      photos:[
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-LOqEaHmz54W80lAcNlK/hbh1ndzyzhybma9mjvkd",
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-LOqEaHmz54W80lAcNlK/ulazedh48lvsrrnn8nec",
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-LOqEaHmz54W80lAcNlK/th9dli0vknpkcx5w8ug9",
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-LOqFfd0QOKVbDjOMKUq/pcxf9gvxzp5ubff0maqz",
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-LOqEaHmz54W80lAcNlK/aa2shhamitegpuyrj1kp"
      ],
      link: "/aptSearch?aptType=wholeApt"
    }],
  }),
  methods: {
    clickType(){
      dataLayer.push({
        'event':'lpListingTypeClicked',
      })
    }
  },
  computed:{
    randomized(){
      let i=Math.floor(Math.random() * 5)
      let j=Math.floor(Math.random() * 5)
      let k=Math.floor(Math.random() * 5)
      return [i,j,k]
    },

  },
}
</script>
<style lang="scss" scoped>
.noScrollbar::-webkit-scrollbar {
  display: none;
}
.noScrollbar{
  -ms-overflow-style:none;
}

</style>
