<template>
<div id="">
  <v-card flat v-if="signUp!=true  && logged==false" class="sizes">
    <v-card-title primary-title>
      <h1 class="headline">Sign in to get your personalised Roombook.</h1>
    </v-card-title>
    <v-card-text>
      <v-layout wrap>
        <v-flex xs12>
          <v-text-field v-model="email" label="Email" :error-messages="emailErrorMessage" @blur="reset"></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-text-field v-model="pwd" label="Password" type="password" :error-messages="pwdErrorMessage" @blur="$v.pwd.$touch()"></v-text-field>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions>
      <v-btn flat @click="signUp=true" left>sign up</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" right :disabled="sending" @click="submit()">Get Roombook</v-btn>
    </v-card-actions>
  </v-card>
  <div class="" v-else-if="signUp==true && logged==false">
    <SignUp class="ma-2 pa-2" :title="'Sign up to get your personalised Roombook.'" @signUpSuccess="signedIn"></SignUp>
  </div>
  <div class="" v-else>
    <div class="pa-1">
      <v-card flat class="sizes" max-width="">
        <v-card-title primary-title>
          <h1 class="headline">{{thankYouTitle}}</h1>
        </v-card-title>
        <v-card-text>
          {{thankYouText}}
        </v-card-text>
      </v-card>
    </div>
    <v-divider class="mx-2 hidden-sm-and-up"></v-divider>
    <SelectRB class="pa-1 " :title="''" :h1="false"></selectRB>
  </div>
</div>
</template>
<script>
import {
  required,
  email
} from 'vuelidate/lib/validators'
import axios from 'axios'
import axiosAuth from '../../../axios-auth'
import config from '../../../config.js'
import AWSSES from '../../../aws-ses'
import SimpleSearch from './LPSimpleSearch.vue'
import SelectRB from './LPSelectRB.vue'
import SignUp from './LPSimpleSignUp.vue'
import * as typos from '../../../store/types'
import {
  mapActions
} from 'vuex'

export default {
  props: [ 'thankYouTitle', 'thankYouText', 'searchTitle'],
  name: "",
  data: () => ({
    email: '',
    pwd: "",
    sending: false,
    signUp: false,
    logged:false,
    generalError: null,
    emailNotUnique: true,
  }),
  methods: {
    ...mapActions({
      setStore: typos.SET_STORE
    }),
    reset() {
      this.emailNotUnique = true
      this.generalError = null
      this.$v.$touch()
    },
    signedIn(payload) {
      console.log('doSignedIn', payload);
      this.email = payload.email
      this.submit()
      this.signUp = false
    },
    submit() {
      this.sending = true
      this.emailNotUnique = true
      this.generalError = null
      this.$v.$touch()
      if (this.$v.$error || this.$v.$invalid) {
        this.sending = false
        return
      } else {
        axiosAuth.post('/verifyPassword?key=' + config.FIREBASE_API_KEY, {
          email: this.email,
          password: this.pwd,
          returnSecureToken: true
        }).then(res => {
          let dataToSend = res.data
          console.log(dataToSend);
          this.setStore(dataToSend)
          this.logged=true
          axios.get('/users.json?orderBy="userId"&equalTo="' + dataToSend.localId + '"')
            .then(res => {
              if (Object.keys(res.data).length > 0) {


              }
            })
        }).catch(err => {
          console.log(err)
          this.generalError = "Email and Password don't match"
          // console.log(this.sending + "this is were it stops ")
          this.sending = false

        })



        // axios.get('/users.json?orderBy="email"&equalTo="' + this.email + '"')
        // .then(res => {
        //   if (Object.keys(res.data).length != 0) {
        //     // console.log('email error');
        //
        //     this.emailNotUnique=true
        //     console.log('all fine, generate coupon');
        //     this.sending=false
        //     return
        //   } else {
        //     this.emailNotUnique=false
        //     this.sending=false
        //     console.log("Email doesn't Exit");
        //     // this.emailNotUnique=true
        //   }
        // })
        // .catch(err=>{
        //   console.log('error: ',err);
        // })

      }

    },
  },
  validations: {
    email: {
      required,
      email
    },
    pwd: {
      required
    }
  },
  computed: {
    emailErrorMessage() {
      let errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Please provide a valid email')
      !this.$v.email.required && errors.push('Email is a required field')
      !this.emailNotUnique && errors.push("This email does not exist. If you don't have a profile yet, please Sign up first")
      !this.generalError == null && errors.push(this.generalError)
      return errors
    },
    pwdErrorMessage() {
      let errors = []
      if (!this.$v.pwd.$dirty) return errors
      !this.$v.pwd.required && errors.push('Please complete your password')
      !this.generalError == null && errors.push(this.generalError)
      return errors
    },
  },
  validations: {
    email: {
      required,
      email
    },
    pwd: {
      required
    }
  },
  components: {
    SelectRB,
    SignUp
  }

}
</script>
<style lang="scss" scoped>
@media only screen and (min-width:600px) {
    .sizes {
        width: 35vw;
    }
}
</style>
