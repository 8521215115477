<template>
<div id="">

  <v-card flat class=" sizes">
    <v-card-title primary-title>
      <h1 v-if="h1==true" class="headline">{{title}}</h1>
      <h2 v-else class="headline"> {{title}}</h2>
    </v-card-title>
    <v-card-text v-if="sent==false">

      <v-layout wrap>
        <v-flex xs12>
          <v-autocomplete :auto-select-first="true" v-model="location" prepend-icon="location_searching" :items="locs" item-value="name" item-text="name" return-object clearable label="WHERE">
            <template slot="no-data">
              <v-list-tile>
                <v-list-tile-title>
                  Nothing result found
                </v-list-tile-title>
              </v-list-tile>
            </template>

            <template slot="selection" slot-scope="{item, selected}">
              <v-icon>{{item.icon}}</v-icon>
              <span class="pl-2">{{item.name}}</span>
            </template>
            <template slot="item" slot-scope="{ item, tile }">
              <v-icon>{{item.icon}}</v-icon>
              <span class="pl-2">{{item.name}}</span>
            </template>
          </v-autocomplete>
        </v-flex>

        <v-flex xs12>
          <!-- <v-menu
              :close-on-content-click="false"
              v-model="dateMenu"
              full-width
              transition="scale-transition"
            >
              <v-text-field
                slot="activator"
                :value="mid"
                label="ARRIVAL"
                prepend-icon="event"
                readonly
              ></v-text-field>
              <v-date-picker v-model="mid" @input="dateMenu = false"></v-date-picker>
            </v-menu> -->
          <v-menu full-width v-model="dateMenu" :close-on-content-click="false" max-width="290">
            <v-text-field slot="activator" prepend-icon="event" :value="mid" label="ARRIVAL" readonly></v-text-field>
            <v-date-picker v-model="mid" @change="dateMenu = false"></v-date-picker>
          </v-menu>
        </v-flex>
        <v-flex xs12>
          <v-select prepend-icon="house" :items="types" v-model="type" label="WHAT"></v-select>
        </v-flex>
        <v-flex xs12>
          <v-text-field v-model="price" label="BUDGET" suffix="TW$" prepend-icon="attach_money"></v-text-field>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-text v-else>
      We have sent your form to our housing experts they will get in touch soon. In order to speed up the process we advice you to check your emails and reply to the confirmation email we have sent you.
      <br>In meanwhile you can already click the button to see our available listings online.
    </v-card-text>
    <v-card-actions>
      <v-flex xs12 class="px-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" block large @click="sendForm" v-if="sent==false">Request help</v-btn>
        <v-btn v-else color="primary" large block @click="performSearch" :to="url">SEARCH online</v-btn>
      </v-flex>
    </v-card-actions>
  </v-card>
</div>
</template>
<script>
import AWSSES from '../../../aws-ses'

export default {
  name: "",
  props: ['title', 'h1', 'pDateMenu'],
  data: () => ({
    location: '',
    mid: '',
    price: '',
    sent: false,
    type: '',
    types: ['Rooms', 'Studios', 'Apartments'],
    locs: [
      {
        "icon": "directions_transit",
        "lat": 25.033972149830436,
        "lng": 121.52848720550537,
        "name": "Dongmen",
        ".key": "-LoU63ZDZomrOsCkA0Zs"
      }, {
        "icon": "room",
        "lat": "25.0333448",
        "lng": "121.5668963",
        "name": "Taiwan",
        ".key": "-LoU63ZDZomrOsCkA0Zx"
      }, {
        "icon": "room",
        "lat": "25.0333448",
        "lng": "121.5668963",
        "name": "Taipei",
        ".key": "-LoU63iv_CS9ZI6rjB_n"
      }, {
        "icon": "room",
        "lat": "25.009235",
        "lng": "121.5200703",
        "name": "New Taipei City",
        ".key": "-LoU63tkWK2qb3YEJgry"
      }, {
        "icon": "room",
        "lat": "25.0333448",
        "lng": "121.5668963",
        "name": "Taipei City Center",
        ".key": "-LoU643SLbSBOskt-Fsp"
      }, {
        "icon": "location_city",
        "lat": "25.0265152",
        "lng": "121.534395",
        "name": "Daan District",
        ".key": "-LoU64uTdcTdMZu1FNjx"
      }, {
        "icon": "location_city",
        "lat": "25.0333448",
        "lng": "121.5668963",
        "name": "Xinyi",
        ".key": "-LoU6543KK6OaSXP5Mm3"
      }, {
        "icon": "location_city",
        "lat": 25.075197,
        "lng": 121.580404,
        "name": "Neihu",
        ".key": "-LoU65EmfsOwahAxEhBA"
      }, {
        "icon": "location_city",
        "lat": "25.0545",
        "lng": "121.61699999999996",
        "name": "Nangang",
        ".key": "-LoU65PeRmGho26EQq1f"
      }, {
        "icon": "location_city",
        "lat": "25.0918396",
        "lng": "121.5242068",
        "name": "Shilin",
        ".key": "-LoU65_olEPf-MoY7bWI"
      }, {
        "icon": "location_city",
        "lat": "25.132419",
        "lng": "121.501379",
        "name": "Beitou",
        ".key": "-LoU65kZ_4kbkPE4bYRm"
      }, {
        "icon": "location_city",
        "lat": "24.9897",
        "lng": "121.56999999999994",
        "name": "Wenshan",
        ".key": "-LoU65vYPpFtTWLQiuLZ"
      }, {
        "icon": "location_city",
        "lat": "25.0137",
        "lng": "121.46399999999994",
        "name": "Banqiao",
        ".key": "-LoU66580KaFP95JwLX-"
      }, {
        "icon": "location_city",
        "lat": "25.0357",
        "lng": "121.45000000000005",
        "name": "Xinzhuang",
        ".key": "-LoU66GLfgBYqaFdOrXd"
      }, {
        "icon": "location_city",
        "lat": "24.9995",
        "lng": "121.50800000000004",
        "name": "Zhonghe",
        ".key": "-LoU66R2p8CD1RZpcjlL"
      }, {
        "icon": "location_city",
        "lat": "25.009235",
        "lng": "121.5200703",
        "name": "Yonghe",
        ".key": "-LoU66ahlQx6hACa_0ZN"
      }, {
        "icon": "location_city",
        "lat": "24.9722",
        "lng": "121.44299999999998",
        "name": "Tucheng",
        ".key": "-LoU66lffHjEiWeoBSA7"
      }, {
        "icon": "location_city",
        "lat": "24.9907",
        "lng": "121.42100000000005",
        "name": "Shulin",
        ".key": "-LoU66weAJ0Z0Vd4xX-x"
      }, {
        "icon": "location_city",
        "lat": "24.9343",
        "lng": "121.36900000000003",
        "name": "Sanxia",
        ".key": "-LoU676QxgxrD0oznTMK"
      }, {
        "icon": "location_city",
        "lat": "25.0614",
        "lng": "121.48800000000006",
        "name": "Sanchong",
        ".key": "-LoU67HHI47NWrEliDwQ"
      }, {
        "icon": "location_city",
        "lat": "25.0849",
        "lng": "121.47399999999993",
        "name": "Luzhou",
        ".key": "-LoU67SB7V1g7vUPcZn_"
      }, {
        "icon": "location_city",
        "lat": "25.0827",
        "lng": "121.43799999999999",
        "name": "Wugu",
        ".key": "-LoU67bvG6BUVl7q1gUh"
      }, {
        "icon": "location_city",
        "lat": "25.0589",
        "lng": "121.43100000000004",
        "name": "Taishan",
        ".key": "-LoU67mWJkAcJwaGnIDd"
      }, {
        "icon": "location_city",
        "lat": "25.0775",
        "lng": "121.39200000000005",
        "name": "Linkou",
        ".key": "-LoU67xN2DfYdWVlaNxb"
      }, {
        "icon": "location_city",
        "lat": "25.1457",
        "lng": "121.404",
        "name": "Bali",
        ".key": "-LoU687B9t0TDslxSyht"
      }, {
        "icon": "location_city",
        "lat": "25.1813",
        "lng": "121.45299999999997",
        "name": "Tamsui",
        ".key": "-LoU68HvMvNt6jNMFN03"
      }, {
        "icon": "location_city",
        "lat": "25.0323",
        "lng": "121.51800000000003",
        "name": "Zhongzheng",
        ".key": "-LoU68SWqweijZ5XPeuJ"
      }, {
        "icon": "location_city",
        "lat": "25.0319",
        "lng": "121.49900000000002",
        "name": "Wanhua",
        ".key": "-LoU68cIpvf4FiV1hbXK"
      }, {
        "icon": "location_city",
        "lat": "25.0659",
        "lng": "121.51600000000008",
        "name": "Datong",
        ".key": "-LoU68mvtut2E9Bh3VSm"
      }, {
        "icon": "location_city",
        "lat": "25.0644",
        "lng": "121.53300000000002",
        "name": "Zhongshan",
        ".key": "-LoU68xgIGZfeObVfrMR"
      }, {
        "icon": "location_city",
        "lat": "25.0499",
        "lng": "121.577",
        "name": "Songshan",
        ".key": "-LoU697tq7vnBSZ5KHDS"
      }, {
        "icon": "school",
        "lat": 25.0423745,
        "lng": 121.615965,
        "name": "Academia Sinica",
        ".key": "-LoU6H29oNSQxgVvG7hQ"
      }, {
        "icon": "school",
        "lat": "25.0335523",
        "lng": "121.6113065",
        "name": "China University of Science and Technology",
        ".key": "-LoU6J4M4YOmbeTI6eqd"
      }, {
        "icon": "school",
        "lat": "24.9980691",
        "lng": "121.5546837",
        "name": "China University of Technology",
        ".key": "-LoU6JF8gNlSnDULzDo5"
      }, {
        "icon": "school",
        "lat": "25.1359607",
        "lng": "121.5389922",
        "name": "Chinese Culture University",
        ".key": "-LoU6JPkfuI8m3IB1Jny"
      }, {
        "icon": "school",
        "lat": "25.1359607",
        "lng": "121.5389922",
        "name": "CCU",
        ".key": "-LoU6J_WFQ_D1dnq6yCM"
      }, {
        "icon": "school",
        "lat": "25.1359607",
        "lng": "121.5389922",
        "name": "PCCU",
        ".key": "-LoU6JkAO7vj8lT6k9b2"
      }, {
        "icon": "school",
        "lat": "25.0877132",
        "lng": "121.5267639",
        "name": "Ming Chuan University",
        ".key": "-LoU6JuvqRHK1eQcc2zT"
      }, {
        "icon": "school",
        "lat": "25.0877132",
        "lng": "121.5267639",
        "name": "MCU",
        ".key": "-LoU6K4hNgpMaqbIWdDJ"
      }, {
        "icon": "school",
        "lat": "25.0877132",
        "lng": "121.5267639",
        "name": "Ming Chuan",
        ".key": "-LoU6KFU0aYdqIyxOwow"
      }, {
        "icon": "school",
        "lat": "25.0877132",
        "lng": "121.5267639",
        "name": "Mingchuan",
        ".key": "-LoU6KQ3pRpZms3lW4bL"
      }, {
        "icon": "school",
        "lat": "24.9815999",
        "lng": "121.5754323",
        "name": "National Chengchi University",
        ".key": "-LoU6KaC3IiverovhSvQ"
      }, {
        "icon": "school",
        "lat": "24.9815999",
        "lng": "121.5754323",
        "name": "NCCU",
        ".key": "-LoU6KkwJFanrV7iOIKL"
      }, {
        "icon": "school",
        "lat": "25.0267432",
        "lng": "121.5301239",
        "name": "National Taiwan Normal University",
        ".key": "-LoU6KwHJ_wuVnZDeBjE"
      }, {
        "icon": "school",
        "lat": "25.0267432",
        "lng": "121.5301239",
        "name": "NTNU",
        ".key": "-LoU6L62eo6I4RXEvnH9"
      }, {
        "icon": "school",
        "lat": "24.9431604",
        "lng": "121.36866",
        "name": "National Taipei University",
        ".key": "-LoU6LH6qfgiGUqZgiJS"
      }, {
        "icon": "school",
        "lat": "24.9431604",
        "lng": "121.36866",
        "name": "NTPU",
        ".key": "-LoU6LRq1m2TBh0tfzBn"
      }, {
        "icon": "school",
        "lat": "25.0149",
        "lng": "121.53399999999999",
        "name": "National Taiwan University",
        ".key": "-LoU6LcB-SUDVkhMgRuo"
      }, {
        "icon": "school",
        "lat": "25.0149",
        "lng": "121.53399999999999",
        "name": "NTU",
        ".key": "-LoU6LnFgl6Ujbd4MK8L"
      }, {
        "icon": "school",
        "lat": "25.0423745",
        "lng": "121.5254455",
        "name": "National Taipei University of Business",
        ".key": "-LoU6LxyWB37-5uhn1Uk"
      }, {
        "icon": "school",
        "lat": "25.0423745",
        "lng": "121.5254455",
        "name": "NTUB",
        ".key": "-LoU6M7uW0nkhO3AwR3Y"
      }, {
        "icon": "school",
        "lat": "25.0234462",
        "lng": "121.5452531",
        "name": "National Taipei University of Education",
        ".key": "-LoU6MIep5BMorGKGcBV"
      }, {
        "icon": "school",
        "lat": "25.0234462",
        "lng": "121.5452531",
        "name": "NTUE",
        ".key": "-LoU6MTtDuVv3I-BuBur"
      }, {
        "icon": "school",
        "lat": "24.994769",
        "lng": "121.5266",
        "name": "National Taipei University of Nursing and Health Science",
        ".key": "-LoU6MdpWpGc-9WfAcBJ"
      }, {
        "icon": "school",
        "lat": "25.0430635",
        "lng": "121.5346877",
        "name": "National Taipei University of Technology",
        ".key": "-LoU6MoT5O9uTvQOXZq_"
      }, {
        "icon": "school",
        "lat": "25.0430635",
        "lng": "121.5346877",
        "name": "NTUT",
        ".key": "-LoU6MzGl-YnoZGVO3QK"
      }, {
        "icon": "school",
        "lat": "25.0430635",
        "lng": "121.5346877",
        "name": "Taipei Tech",
        ".key": "-LoU6N8rUWCGvpBzKUtB"
      }, {
        "icon": "school",
        "lat": "25.013411",
        "lng": "121.5416198",
        "name": "National Taiwan University of Science and Technology",
        ".key": "-LoU6NJQUDRCJDvm1kIL"
      }, {
        "icon": "school",
        "lat": "25.013411",
        "lng": "121.5416198",
        "name": "NTUST",
        ".key": "-LoU6NUVdefFd1q131Uw"
      }, {
        "icon": "school",
        "lat": "25.013411",
        "lng": "121.5416198",
        "name": "Taiwan Tech",
        ".key": "-LoU6NeIZLbOu2MQCFpV"
      }, {
        "icon": "school",
        "lat": "25.11912",
        "lng": "121.51223",
        "name": "National Yang-Ming University",
        ".key": "-LoU6Np4CgI32AaPyz2b"
      }, {
        "icon": "school",
        "lat": "25.0798",
        "lng": "121.54700000000003",
        "name": "Shih Chien University",
        ".key": "-LoU6NzrvVzh8XXwml4r"
      }, {
        "icon": "school",
        "lat": "24.9896185",
        "lng": "121.5468188",
        "name": "Shih Hsin University",
        ".key": "-LoU6O9apXluaIHspPmr"
      }, {
        "icon": "school",
        "lat": "25.0947592",
        "lng": "121.5454306",
        "name": "Soochow University",
        ".key": "-LoU6OKrWz1Q2dSP8SWd"
      }, {
        "icon": "school",
        "lat": "25.0947592",
        "lng": "121.5454306",
        "name": "SCU",
        ".key": "-LoU6OVkQ0G4erNMU17J"
      }, {
        "icon": "school",
        "lat": "25.1345772",
        "lng": "121.4691357",
        "name": "Taipei City University of Science and Technology",
        ".key": "-LoU6OfPuXPnrTT-yILj"
      }, {
        "icon": "school",
        "lat": "25.0253208",
        "lng": "121.5614418",
        "name": "Taipei Medical University",
        ".key": "-LoU6OrGlGumlqXtmTxi"
      }, {
        "icon": "school",
        "lat": "25.0253208",
        "lng": "121.5614418",
        "name": "TMU",
        ".key": "-LoU6P0w7o5oh9ODL3dZ"
      }, {
        "icon": "school",
        "lat": "25.0253208",
        "lng": "121.5614418",
        "name": "Taipei National University of the Arts",
        ".key": "-LoU6PBkBXa7QigrKnTX"
      }, {
        "icon": "school",
        "lat": "25.0873291",
        "lng": "121.5650007",
        "name": "Takming University of Science and Technology",
        ".key": "-LoU6PMWzIrpCcOSx3tN"
      }, {
        "icon": "school",
        "lat": "25.0672165",
        "lng": "121.5210356",
        "name": "Tatung University",
        ".key": "-LoU6PXfjXEzrRuDkFI9"
      }, {
        "icon": "school",
        "lat": "25.0672165",
        "lng": "121.5210356",
        "name": "TTU",
        ".key": "-LoU6PhH7MZwNwuATQOl"
      }, {
        "icon": "school",
        "lat": "25.0362325",
        "lng": "121.5137742",
        "name": "University of Taipei",
        ".key": "-LoU6Ps73pPvjxCVcJY-"
      }, {
        "icon": "school",
        "lat": "25.039276",
        "lng": "121.4329572",
        "name": "Fu Jen Catholic University",
        ".key": "-LoU6Q1rh7zG1CCvSxIF"
      }, {
        "icon": "school",
        "lat": "25.039276",
        "lng": "121.4329572",
        "name": "FJU",
        ".key": "-LoU6QD4QqZ-4aa45-RB"
      }, {
        "icon": "school",
        "lat": "25.039276",
        "lng": "121.4329572",
        "name": "Fu Jen",
        ".key": "-LoU6QO-tVabA5BFlbjO"
      }, {
        "icon": "school",
        "lat": "25.039276",
        "lng": "121.4329572",
        "name": "FJCU",
        ".key": "-LoU6QYkpbmQE9EkgVZ1"
      }, {
        "icon": "school",
        "lat": "25.039276",
        "lng": "121.4329572",
        "name": "Fu Ren",
        ".key": "-LoU6QiTJuJkziB7Q50B"
      }, {
        "icon": "school",
        "lat": "25.0149",
        "lng": "121.53399999999999",
        "name": "ICLP",
        ".key": "-LoU6QtKK56aA-wn3jYS"
      }, {
        "icon": "school",
        "lat": "25.0149",
        "lng": "121.53399999999999",
        "name": "NTU ICLP",
        ".key": "-LoU6R3657rO_voY9o4N"
      }, {
        "icon": "school",
        "lat": "25.0149",
        "lng": "121.53399999999999",
        "name": "NTU International Chinese Language Program",
        ".key": "-LoU6RDyjBV7fQleHCgk"
      }, {
        "icon": "school",
        "lat": "25.0149",
        "lng": "121.53399999999999",
        "name": "International Chinese Language Program",
        ".key": "-LoU6RQLQsh8Qy6rIzPs"
      }, {
        "icon": "school",
        "lat": "25.0149",
        "lng": "121.53399999999999",
        "name": "CLD",
        ".key": "-LoU6Ra-grxrupkEn6rC"
      }, {
        "icon": "school",
        "lat": "25.0149",
        "lng": "121.53399999999999",
        "name": "NTU CLD",
        ".key": "-LoU6RkmggTPI_7wWLhl"
      }, {
        "icon": "school",
        "lat": "25.0149",
        "lng": "121.53399999999999",
        "name": "NTU Chinese Language Division",
        ".key": "-LoU6RvZM0WUde7-OJES"
      }, {
        "icon": "school",
        "lat": "25.0149",
        "lng": "121.53399999999999",
        "name": "Chinese Language Division",
        ".key": "-LoU6S5YZaRFoXTjRvZa"
      }, {
        "icon": "school",
        "lat": "25.02584517692548",
        "lng": "121.53805732727051",
        "name": "MLC",
        ".key": "-LoU6SGGvK24jqrjWkvL"
      }, {
        "icon": "school",
        "lat": "25.02584517692548",
        "lng": "121.53805732727051",
        "name": "CCU MLC",
        ".key": "-LoU6SR5Yv-ZA7RJr-AO"
      }, {
        "icon": "school",
        "lat": "25.02584517692548",
        "lng": "121.53805732727051",
        "name": "PCCU MLC",
        ".key": "-LoU6SavKcYMOu98f9Y9"
      }, {
        "icon": "school",
        "lat": "25.02584517692548",
        "lng": "121.53805732727051",
        "name": "CCU Mandarin Language Center",
        ".key": "-LoU6SlafFTKAiPTV676"
      }, {
        "icon": "school",
        "lat": "25.02584517692548",
        "lng": "121.53805732727051",
        "name": "Mandarin Language Center",
        ".key": "-LoU6SwT_pPDogabaRtu"
      }, {
        "icon": "school",
        "lat": "25.02584517692548",
        "lng": "121.53805732727051",
        "name": "PCCU Mandarin Language Center",
        ".key": "-LoU6T64rHCIbP8y1E7l"
      }, {
        "icon": "school",
        "lat": "25.027147861915754",
        "lng": "121.5288519859314",
        "name": "MTC",
        ".key": "-LoU6THF3jau6XdO_63W"
      }, {
        "icon": "school",
        "lat": "25.027147861915754",
        "lng": "121.5288519859314",
        "name": "NTNU MTC",
        ".key": "-LoU6TSXix-dHuGOknqJ"
      }, {
        "icon": "school",
        "lat": "25.027147861915754",
        "lng": "121.5288519859314",
        "name": "NTNU Mandarin Training Center",
        ".key": "-LoU6TcCxac_RPSy-hl_"
      }, {
        "icon": "school",
        "lat": "25.027147861915754",
        "lng": "121.5288519859314",
        "name": "Mandarin Training Center",
        ".key": "-LoU6Tn2qOUl4ylevANj"
      }, {
        "icon": "school",
        "lat": "24.9815999",
        "lng": "121.5754323",
        "name": "NCCU CLC",
        ".key": "-LoU6Ty7A-f3a7Uh6cvo"
      }, {
        "icon": "school",
        "lat": "24.9815999",
        "lng": "121.5754323",
        "name": "CLC NCCU",
        ".key": "-LoU6U93ZEKnEOViae_T"
      }, {
        "icon": "school",
        "lat": "24.9815999",
        "lng": "121.5754323",
        "name": "NCCU Chinese Language Center",
        ".key": "-LoU6UK0ARelcK__9PCR"
      }, {
        "icon": "school",
        "lat": "24.9815999",
        "lng": "121.5754323",
        "name": "National Chengchi University Language Center",
        ".key": "-LoU6UV_fL7yT6bnV3N-"
      }, {
        "icon": "school",
        "lat": "25.031070415668093",
        "lng": "121.52852475643158",
        "name": "CLC TKU",
        ".key": "-LoU6UfMc3F5zxIr2cHd"
      }, {
        "icon": "school",
        "lat": "25.031070415668093",
        "lng": "121.52852475643158",
        "name": "Tamkang University Chinese Language Center",
        ".key": "-LoU6UqYUX40NMe-oUm8"
      }, {
        "icon": "school",
        "lat": "25.031070415668093",
        "lng": "121.52852475643158",
        "name": "Tamkang Chinese Language Center",
        ".key": "-LoU6V0mzFm_M1UzF9rU"
      }, {
        "icon": "school",
        "lat": "25.031070415668093",
        "lng": "121.52852475643158",
        "name": "Tamkang CLC",
        ".key": "-LoU6VBpTWQacxINrPkx"
      }, {
        "icon": "school",
        "lat": "25.031070415668093",
        "lng": "121.52852475643158",
        "name": "TKU Chinese Language Center",
        ".key": "-LoU6VMRc6HHvlJQNBL-"
      }, {
        "icon": "school",
        "lat": "25.031070415668093",
        "lng": "121.52852475643158",
        "name": "TKU CLC",
        ".key": "-LoU6VXEnN4NluDO76t_"
      }, {
        "icon": "school",
        "lat": "25.02258840395553",
        "lng": "121.52634680271149",
        "name": "TMI",
        ".key": "-LoU6VhA4YwZUk8QsdUj"
      }, {
        "icon": "school",
        "lat": "25.02258840395553",
        "lng": "121.52634680271149",
        "name": "Taiwan Mandarin Institute",
        ".key": "-LoU6VrufwbvRXHAtJFH"
      }, {
        "icon": "school",
        "lat": "25.021596772337524",
        "lng": "121.5268886089325",
        "name": "TLI",
        ".key": "-LoU6W1g8RzjILv45XOj"
      }, {
        "icon": "school",
        "lat": "25.021596772337524",
        "lng": "121.5268886089325",
        "name": "Taipei Language Institute",
        ".key": "-LoU6WCXUZUjp5y1A-Jm"
      }, {
        "icon": "local_dining",
        "lat": "25.0307677",
        "lng": "121.529516",
        "name": "Yongkang Street",
        ".key": "-LoU6Y9vbC8efWatZAcw"
      }, {
        "icon": "local_dining",
        "lat": "25.0241558",
        "lng": "121.529142",
        "name": "Shida Night Market",
        ".key": "-LoU6YKh8PO5jjA-uPA1"
      }, {
        "icon": "local_dining",
        "lat": "25.0298963",
        "lng": "121.5553773",
        "name": "Tonghua Night Market",
        ".key": "-LoU6YVZyg9o68-OCmTD"
      }, {
        "icon": "local_dining",
        "lat": "25.0149166",
        "lng": "121.534264",
        "name": "Gongguan Night Market",
        ".key": "-LoU6Yf8WKyeqm9Ee6jK"
      }, {
        "icon": "local_dining",
        "lat": "25.0883482",
        "lng": "121.5251017",
        "name": "Shilin Night Market",
        ".key": "-LoU6YpuW_qZe_g1FTow"
      }, {
        "icon": "local_dining",
        "lat": "25.044231",
        "lng": "121.5063694",
        "name": "Ximending",
        ".key": "-LoU6Z-mD7vawV9zoRC2"
      }, {
        "icon": "local_dining",
        "lat": "25.044231",
        "lng": "121.5063694",
        "name": "Ximen night market",
        ".key": "-LoU6ZAYIJdEA6esn-UV"
      }, {
        "icon": "local_dining",
        "lat": "25.0556695",
        "lng": "121.5150642",
        "name": "Ningxia night market",
        ".key": "-LoU6ZLJtk1d1eJiTg8R"
      }, {
        "icon": "local_dining",
        "lat": "25.0506461",
        "lng": "121.5754669",
        "name": "Raohe Night Market",
        ".key": "-LoU6ZW9ri-s4s4Z2MQU"
      }, {
        "icon": "room",
        "lat": 24.140289,
        "lng": 120.681817,
        "name": "Taichung",
        ".key": "-LoU6_0uu244FuvMqla6"
      }, {
        "icon": "room",
        "lat": "25.0288947",
        "lng": "121.5538609",
        "name": "Tonghua Street",
        ".key": "-LoU6_rO1G9walgNi2mu"
      }, {
        "icon": "room",
        "lat": "25.0204183",
        "lng": "121.5225267",
        "name": "Shida Road",
        ".key": "-LoU6a17MSKCHa6Rwfs_"
      }, {
        "icon": "room",
        "lat": "25.0518253",
        "lng": "121.5491968",
        "name": "Dunhua North Road",
        ".key": "-LoU6aBojsWhiNJOZtNt"
      }, {
        "icon": "room",
        "lat": "25.0336862",
        "lng": "121.5648103",
        "name": "Xinyi Road",
        ".key": "-LoU6aMXiqtY-A8AbUL5"
      }, {
        "icon": "room",
        "lat": "25.029500437084856",
        "lng": "121.5582275390625",
        "name": "Keelung Road",
        ".key": "-LoU6aXIeDBRyXELiK2R"
      }, {
        "icon": "room",
        "lat": "25.036693982138722",
        "lng": "121.54990196228027",
        "name": "Ren'ai Road",
        ".key": "-LoU6ahBGGx-Gx1EUf5w"
      }, {
        "icon": "room",
        "lat": "25.0161438",
        "lng": "121.5329889",
        "name": "Roosevelt Road",
        ".key": "-LoU6as1hc4409AM9zSS"
      }, {
        "icon": "photo_filter",
        "lat": "25.0336862",
        "lng": "121.5648103",
        "name": "Taipei 101",
        ".key": "-LoU6cOmmTC-lk08iytI"
      }, {
        "icon": "photo_filter",
        "lat": "25.0353651",
        "lng": "121.5660528",
        "name": "ATT 4 Fun",
        ".key": "-LoU6cZovILcdfep8siR"
      }, {
        "icon": "photo_filter",
        "lat": "25.0413418",
        "lng": "121.5573918",
        "name": "Sun Yat Sen Memorial Hall",
        ".key": "-LoU6cjetWUkrqv7yes9"
      }, {
        "icon": "photo_filter",
        "lat": "25.0333",
        "lng": "121.51800000000003",
        "name": "Chiang Kai-Shek Memorial Hall ",
        ".key": "-LoU6cuMWu_sU0TgXeL5"
      }, {
        "icon": "photo_filter",
        "lat": "25.0848706",
        "lng": "121.5556538",
        "name": "Miramar",
        ".key": "-LoU6d4T61C4N5cG5W-j"
      }, {
        "icon": "photo_filter",
        "lat": "25.0335",
        "lng": "121.53499999999997",
        "name": "Da'an Park",
        ".key": "-LoU6dG3_w8yQ4SsEEAn"
      }, {
        "icon": "photo_filter",
        "lat": "33.897",
        "lng": "-111.976",
        "name": "Elephant Mountain",
        ".key": "-LoU6dQszl-cwQeOicR3"
      }, {
        "icon": "photo_filter",
        "lat": "24.9983",
        "lng": "121.57899999999995",
        "name": "Taipei Zoo",
        ".key": "-LoU6daXqhVbBGcOIJQg"
      }, {
        "icon": "photo_filter",
        "lat": "25.0406",
        "lng": "121.56500000000005",
        "name": "Taipei City Hall",
        ".key": "-LoU6dlZzlNmftLjwPwM"
      }, {
        "icon": "directions_transit",
        "lat": "25.0599",
        "lng": "121.61599999999999",
        "name": "Nangang Software Park",
        ".key": "-LoU6iBwYpiuISYiCCAS"
      }, {
        "icon": "directions_transit",
        "lat": "25.0673975",
        "lng": "121.6116839",
        "name": "Donghu",
        ".key": "-LoU6iMd9L4ivOc9m4nv"
      }, {
        "icon": "directions_transit",
        "lat": "25.0726385",
        "lng": "121.6073633",
        "name": "Huzhou",
        ".key": "-LoU6iX_YPVAAvNN-Q-0"
      }, {
        "icon": "directions_transit",
        "lat": "25.0837705",
        "lng": "121.6023504",
        "name": "Dahu Park",
        ".key": "-LoU6ihuCjobg-9Oj14Y"
      }, {
        "icon": "directions_transit",
        "lat": "25.0837",
        "lng": "121.59400000000005",
        "name": "Neihu",
        ".key": "-LoU6isxjijuEZS3vJnX"
      }, {
        "icon": "directions_transit",
        "lat": "25.0785562",
        "lng": "121.5849416",
        "name": "Wende",
        ".key": "-LoU6j2frBj2ATvwx-25"
      }, {
        "icon": "directions_transit",
        "lat": "25.0800673",
        "lng": "121.5753301",
        "name": "Gangqian",
        ".key": "-LoU6jDi04CKhTv4PjuT"
      }, {
        "icon": "directions_transit",
        "lat": "25.0821",
        "lng": "121.56700000000001",
        "name": "Xihu",
        ".key": "-LoU6jOU6BiaJAe8JbmO"
      }, {
        "icon": "directions_transit",
        "lat": "25.0848",
        "lng": "121.55600000000004",
        "name": "Jiannan Road",
        ".key": "-LoU6jZJvq7xssNnBwKd"
      }, {
        "icon": "directions_transit",
        "lat": "25.0798",
        "lng": "121.54700000000003",
        "name": "Dazhi",
        ".key": "-LoU6jivfO01VKBtkE-S"
      }, {
        "icon": "directions_transit",
        "lat": "25.063",
        "lng": "121.55200000000002",
        "name": "Songshan Airport",
        ".key": "-LoU6jtbN5dpGmocZXcJ"
      }, {
        "icon": "directions_transit",
        "lat": "25.0606",
        "lng": "121.54399999999998",
        "name": "Zhongshan Junior High School",
        ".key": "-LoU6k3QfA_Fqd3lekv5"
      }, {
        "icon": "directions_transit",
        "lat": "25.0333",
        "lng": "121.543",
        "name": "Daan",
        ".key": "-LoU6k_7k1B8YgSZNT3c"
      }, {
        "icon": "directions_transit",
        "lat": "25.0261",
        "lng": "121.543",
        "name": "Technology Building",
        ".key": "-LoU6kkH_-Nw3iNDZ1_X"
      }, {
        "icon": "directions_transit",
        "lat": "25.0238",
        "lng": "121.553",
        "name": "Liuzhangli",
        ".key": "-LoU6kv5AUNU-CJsqxF4"
      }, {
        "icon": "directions_transit",
        "lat": "25.0184395",
        "lng": "121.5589054",
        "name": "Linguang",
        ".key": "-LoU6l4lHYhYj6j2PtlD"
      }, {
        "icon": "directions_transit",
        "lat": "25.0054814",
        "lng": "121.5570598",
        "name": "Xinhai",
        ".key": "-LoU6lFgChuCSRgPRlt6"
      }, {
        "icon": "directions_transit",
        "lat": "24.9994",
        "lng": "121.55799999999999",
        "name": "Wanfang Hospital",
        ".key": "-LoU6lQOgZDQ7Q0Z1Dsr"
      }, {
        "icon": "directions_transit",
        "lat": "24.9985",
        "lng": "121.56799999999998",
        "name": "Wanfang Community",
        ".key": "-LoU6la-uCv3Gi-6kt6D"
      }, {
        "icon": "directions_transit",
        "lat": "24.9982",
        "lng": "121.57299999999998",
        "name": "Muzha",
        ".key": "-LoU6lkdJ14co1jZ019S"
      }, {
        "icon": "directions_transit",
        "lat": "24.9983",
        "lng": "121.57899999999995",
        "name": "Taipei Zoo",
        ".key": "-LoU6lvOuZ604KSLIHMx"
      }, {
        "icon": "directions_transit",
        "lat": "25.16781460041157",
        "lng": "121.44561767578125",
        "name": "Tamsui",
        ".key": "-LoU6rS7keMMB7qoMtfA"
      }, {
        "icon": "directions_transit",
        "lat": "25.154763251283125",
        "lng": "121.45883560180664",
        "name": "Hongshulin",
        ".key": "-LoU6rbzfNkJnp_G5948"
      }, {
        "icon": "directions_transit",
        "lat": "25.137048472851355",
        "lng": "121.4593505859375",
        "name": "Zhuwei",
        ".key": "-LoU6rmq_8fC9Ynu4PYm"
      }, {
        "icon": "directions_transit",
        "lat": "25.125548030312743",
        "lng": "121.46707534790039",
        "name": "Guandu",
        ".key": "-LoU6rxoSF-rPjKwbhvZ"
      }, {
        "icon": "directions_transit",
        "lat": "25.130987563772376",
        "lng": "121.4732551574707",
        "name": "Zhongyi",
        ".key": "-LoU6s7XP7LWNYF5Xp9Z"
      }, {
        "icon": "directions_transit",
        "lat": "25.13751468416722",
        "lng": "121.48544311523438",
        "name": "Fuxinggang",
        ".key": "-LoU6sIGnu5s-w4B0Cva"
      }, {
        "icon": "directions_transit",
        "lat": "25.1369012",
        "lng": "121.502732",
        "name": "Xinbeitou",
        ".key": "-LoU6sT0RPC9HR8zyFWN"
      }, {
        "icon": "directions_transit",
        "lat": "25.125548030312743",
        "lng": "121.50106430053711",
        "name": "Beitou",
        ".key": "-LoU6scZm8_D9hLFZxrH"
      }, {
        "icon": "directions_transit",
        "lat": "25.120885380289486",
        "lng": "121.50604248046875",
        "name": "Qiyan",
        ".key": "-LoU6snDL0-u3r3yTEUa"
      }, {
        "icon": "directions_transit",
        "lat": "25.120885380289486",
        "lng": "121.50612831115723",
        "name": "Qili'an",
        ".key": "-LoU6sxoHsx8DRnVeMPW"
      }, {
        "icon": "directions_transit",
        "lat": "25.1146071",
        "lng": "121.5155232",
        "name": "Shipai",
        ".key": "-LoU6t7e4Uf4BwNueNy3"
      }, {
        "icon": "directions_transit",
        "lat": "25.11000517170635",
        "lng": "121.51874542236328",
        "name": "Mingde",
        ".key": "-LoU6tIjRmcQUMsUiEyJ"
      }, {
        "icon": "directions_transit",
        "lat": "25.103087964186507",
        "lng": "121.52243614196777",
        "name": "Zhishan",
        ".key": "-LoU6tTdixSHmuU2yOeY"
      }, {
        "icon": "directions_transit",
        "lat": "25.093605313622025",
        "lng": "121.52575492771575",
        "name": "Shilin",
        ".key": "-LoU6tdjXwiFFDb3MOXm"
      }, {
        "icon": "directions_transit",
        "lat": "25.084510605833014",
        "lng": "121.52501106262207",
        "name": "Jiantan",
        ".key": "-LoU6toRwoN3gId7XLha"
      }, {
        "icon": "directions_transit",
        "lat": "25.07139204857084",
        "lng": "121.5201187133789",
        "name": "Yuanshan",
        ".key": "-LoU6tz5-pN1XV-2y-ku"
      }, {
        "icon": "directions_transit",
        "lat": "25.057805572549068",
        "lng": "121.52063369750977",
        "name": "Shuanglian",
        ".key": "-LoU6uJQHBL2skYRxMTI"
      }, {
        "icon": "directions_transit",
        "lat": "25.04209858440272",
        "lng": "121.51625633239746",
        "name": "National Taiwan University Hospital",
        ".key": "-LoU6uongJ9_CSLBxXvM"
      }, {
        "icon": "directions_transit",
        "lat": "25.03358331171376",
        "lng": "121.5356969833374",
        "name": "Daan Park",
        ".key": "-LoU6vJrwWsdTghjm-je"
      }, {
        "icon": "directions_transit",
        "lat": "25.033155588361986",
        "lng": "121.55299186706543",
        "name": "Xinyi Anhe",
        ".key": "-LoU6veEFTLySwHYEbgD"
      }, {
        "icon": "directions_transit",
        "lat": "25.033038936279954",
        "lng": "121.56273365020752",
        "name": "Taipei 101/World Trade Center",
        ".key": "-LoU6vpBq4sMhHciO5iN"
      }, {
        "icon": "directions_transit",
        "lat": "25.0328445158968",
        "lng": "121.57007217407227",
        "name": "Xiangshan",
        ".key": "-LoU6vzwtMlcoGv8zgK-"
      }, {
        "icon": "directions_transit",
        "lat": "25.1187072",
        "lng": "121.5300015",
        "name": "Tienmu",
        ".key": "-LoU6w9z1wBC8niBIJPv"
      }, {
        "icon": "directions_transit",
        "lat": "25.1187072",
        "lng": "121.5300015",
        "name": "Tianmu",
        ".key": "-LoU6wKiXTUdg9K2CKu2"
      }, {
        "icon": "directions_transit",
        "lat": "25.05",
        "lng": "121.577",
        "name": "Songshan",
        ".key": "-LoU7-BRNqqp1tt3KAuk"
      }, {
        "icon": "directions_transit",
        "lat": "25.05146858931561",
        "lng": "121.56384944915771",
        "name": "Nanjing Sanmin",
        ".key": "-LoU7-M3mE6nag0UmX4a"
      }, {
        "icon": "directions_transit",
        "lat": "25.0516629801752",
        "lng": "121.551833152771",
        "name": "Taipei Arena",
        ".key": "-LoU7-WfPskdfa_-T0jK"
      }, {
        "icon": "directions_transit",
        "lat": "25.051935126860762",
        "lng": "121.54397964477539",
        "name": "Nanjing Fuxing",
        ".key": "-LoU7-gPuW2LdPntaQmY"
      }, {
        "icon": "directions_transit",
        "lat": "25.05267380767591",
        "lng": "121.52039766311646",
        "name": "Zhongshan",
        ".key": "-LoU700viDnR5xARIRLj"
      }, {
        "icon": "directions_transit",
        "lat": "25.04907104866186",
        "lng": "121.51033136097658",
        "name": "Beimen",
        ".key": "-LoU70B_ggNgrTQ0XKht"
      }, {
        "icon": "directions_transit",
        "lat": "25.04221522786921",
        "lng": "121.50827407836914",
        "name": "Ximen",
        ".key": "-LoU70MQq8EuqGNbDWLr"
      }, {
        "icon": "directions_transit",
        "lat": "25.03556637322376",
        "lng": "121.51119232177734",
        "name": "Xiaonanmen",
        ".key": "-LoU70X5KQwXLP9VOB8f"
      }, {
        "icon": "directions_transit",
        "lat": "25.032650095205543",
        "lng": "121.51827335357666",
        "name": "Chiang Kai-shek Memorial Hall (CKS)",
        ".key": "-LoU70gyo5Tl503WJ8an"
      }, {
        "icon": "directions_transit",
        "lat": "25.020634298694397",
        "lng": "121.52831554412842",
        "name": "Taipower Building",
        ".key": "-LoU712A8ZgftAjDvAf2"
      }, {
        "icon": "directions_transit",
        "lat": "25.014839853464466",
        "lng": "121.53421640396118",
        "name": "Gongguan",
        ".key": "-LoU71D0oBfYuiT3aO-N"
      }, {
        "icon": "directions_transit",
        "lat": "25.00169439356087",
        "lng": "121.53908729553223",
        "name": "Wanlong",
        ".key": "-LoU71NUMi_aGn6GEPFK"
      }, {
        "icon": "directions_transit",
        "lat": "24.992865144196657",
        "lng": "121.54080390930176",
        "name": "Jingmei",
        ".key": "-LoU71Y6P0hC80_R6o60"
      }, {
        "icon": "directions_transit",
        "lat": "24.982946067962136",
        "lng": "121.54131889343262",
        "name": "Dapinglin",
        ".key": "-LoU71hok1R-lAzj9zpF"
      }, {
        "icon": "directions_transit",
        "lat": "24.975788276718372",
        "lng": "121.5428638458252",
        "name": "Qizhang",
        ".key": "-LoU71smPdOX6e0GuRbZ"
      }, {
        "icon": "directions_transit",
        "lat": "24.9717696",
        "lng": "121.5305995",
        "name": "Xiaobitan",
        ".key": "-LoU722h8xZcqkwMKT1B"
      }, {
        "icon": "directions_transit",
        "lat": "24.967929787116503",
        "lng": "121.54149055480957",
        "name": "Xindian District Office",
        ".key": "-LoU72DIXUMM11gEKeyv"
      }, {
        "icon": "directions_transit",
        "lat": "24.9582032386907",
        "lng": "121.53805732727051",
        "name": "Xindian",
        ".key": "-LoU72Nz4or2KlQuiA1H"
      }, {
        "icon": "directions_transit",
        "lat": "25.09108983511147",
        "lng": "121.46424431871628",
        "name": "Luzhou",
        ".key": "-LoU776Sm22M7qVeegSD"
      }, {
        "icon": "directions_transit",
        "lat": "25.08569606545418",
        "lng": "121.47308349609375",
        "name": "Sanmin Senior High School",
        ".key": "-LoU77HD-RCZ_IQYaNXD"
      }, {
        "icon": "directions_transit",
        "lat": "25.080643206918786",
        "lng": "121.47971391677856",
        "name": "St. Ignatius High School",
        ".key": "-LoU77ajRFE17nd8L5wV"
      }, {
        "icon": "directions_transit",
        "lat": "25.076814555992293",
        "lng": "121.48645162582397",
        "name": "Sanhe Junior High School",
        ".key": "-LoU77lbGmteKJyu6qqE"
      }, {
        "icon": "directions_transit",
        "lat": "25.070672916651077",
        "lng": "121.49675130844116",
        "name": "Sanchong Elementary School",
        ".key": "-LoU786IcMDAmB7UHzvF"
      }, {
        "icon": "directions_transit",
        "lat": "25.021937038991613",
        "lng": "121.41160726547241",
        "name": "Huilong/Lo-Sheng",
        ".key": "-LoU78HTBYLUXLfkRPTV"
      }, {
        "icon": "directions_transit",
        "lat": "25.02887827283181",
        "lng": "121.42242193222046",
        "name": "Danfeng",
        ".key": "-LoU78SLXdCNo-vqX9jW"
      }, {
        "icon": "directions_transit",
        "lat": "25.032727863519007",
        "lng": "121.43559694290161",
        "name": "Fu Jen University",
        ".key": "-LoU78c2Vz4anRno7Skv"
      }, {
        "icon": "directions_transit",
        "lat": "25.036130178976894",
        "lng": "121.45246267318726",
        "name": "Xinzhuang",
        ".key": "-LoU78mmGoP9kzjPzUEO"
      }, {
        "icon": "directions_transit",
        "lat": "25.039629605002137",
        "lng": "121.46158218383789",
        "name": "Touqianzhuang",
        ".key": "-LoU797vPdQipxnGlG4K"
      }, {
        "icon": "directions_transit",
        "lat": "25.04629767929873",
        "lng": "121.47138833999634",
        "name": "Xianse Temple",
        ".key": "-LoU79Ih74TGjINijCev"
      }, {
        "icon": "directions_transit",
        "lat": "25.055783993855464",
        "lng": "121.48432731628418",
        "name": "Sanchong",
        ".key": "-LoU79TMQxvRl9GG--20"
      }, {
        "icon": "directions_transit",
        "lat": "25.060176997357704",
        "lng": "121.49205207824707",
        "name": "Cailiao",
        ".key": "-LoU79czr4D2OmuKTyff"
      }, {
        "icon": "directions_transit",
        "lat": "25.063092620696068",
        "lng": "121.50012016296387",
        "name": "Taipei Bridge",
        ".key": "-LoU79ntO9zsHCrZzuo3"
      }, {
        "icon": "directions_transit",
        "lat": "25.063092620696068",
        "lng": "121.51359558105469",
        "name": "Daqiaotou",
        ".key": "-LoU79ybZGdR1k6IoEVV"
      }, {
        "icon": "directions_transit",
        "lat": "25.062898247965357",
        "lng": "121.51968955993652",
        "name": "Minquan West Road",
        ".key": "-LoU7A8M1-v8Ybrssp29"
      }, {
        "icon": "directions_transit",
        "lat": "25.062742749558748",
        "lng": "121.52642726898193",
        "name": "Zhongshan Elementary School",
        ".key": "-LoU7AJCmRpQ_7hfUscg"
      }, {
        "icon": "directions_transit",
        "lat": "25.059749366767463",
        "lng": "121.5331220626831",
        "name": "Xingtian Temple",
        ".key": "-LoU7ATtTxJ0jk8yJ6Xj"
      }, {
        "icon": "directions_transit",
        "lat": "25.052090638981234",
        "lng": "121.53299331665039",
        "name": "Songjiang Nanjing",
        ".key": "-LoU7Ado7Md9HofKIQmI"
      }, {
        "icon": "directions_transit",
        "lat": "25.041826415882806",
        "lng": "121.53282165527344",
        "name": "Zhongxiao Xinsheng",
        ".key": "-LoU7AoYcQrl3tuf2LyZ"
      }, {
        "icon": "directions_transit",
        "lat": "25.026584014885913",
        "lng": "121.52277946472168",
        "name": "Guting",
        ".key": "-LoU7B8sQp5kQY12QG9z"
      }, {
        "icon": "directions_transit",
        "lat": "25.013478702505456",
        "lng": "121.51544094085693",
        "name": "Dingxi",
        ".key": "-LoU7BJpk5GVYAckH_15"
      }, {
        "icon": "directions_transit",
        "lat": "25.002316695573565",
        "lng": "121.51097774505615",
        "name": "Yongan Market",
        ".key": "-LoU7BUThe5NAjzhHLZz"
      }, {
        "icon": "directions_transit",
        "lat": "24.993915352469664",
        "lng": "121.50458335876465",
        "name": "Jingan",
        ".key": "-LoU7Be7LFyEi7K14Snz"
      }, {
        "icon": "directions_transit",
        "lat": "24.989947851911385",
        "lng": "121.50900363922119",
        "name": "Nanshijiao",
        ".key": "-LoU7Bom8vCnlx5-I30q"
      }, {
        "icon": "directions_transit",
        "lat": "25.0553158",
        "lng": "121.617896",
        "name": "Nangang Exhibition Center",
        ".key": "-LoU7GDZZyxZ3MjDhYsu"
      }, {
        "icon": "directions_transit",
        "lat": "25.052168394967506",
        "lng": "121.60702228546143",
        "name": "Nangang",
        ".key": "-LoU7GOJ4fNGueHO2YbC"
      }, {
        "icon": "directions_transit",
        "lat": "25.050535508898516",
        "lng": "121.59324645996094",
        "name": "Kunyang",
        ".key": "-LoU7GYu2eNzgqAzeP-b"
      }, {
        "icon": "directions_transit",
        "lat": "25.0450146377808",
        "lng": "121.58247470855713",
        "name": "Houshanpi",
        ".key": "-LoU7GifnEkgMtPoRUHK"
      }, {
        "icon": "directions_transit",
        "lat": "25.040893262086016",
        "lng": "121.57599449157715",
        "name": "Yongchun",
        ".key": "-LoU7GtXcmxwiZpULYFQ"
      }, {
        "icon": "directions_transit",
        "lat": "25.041126551200858",
        "lng": "121.5662956237793",
        "name": "Taipei City Hall",
        ".key": "-LoU7H37WpxaEU82ValL"
      }, {
        "icon": "directions_transit",
        "lat": "25.04139872127398",
        "lng": "121.55749797821045",
        "name": "Sun Yat-sen Memorial Hall",
        ".key": "-LoU7HFvnkLDnexyL4vl"
      }, {
        "icon": "directions_transit",
        "lat": "25.04151536540612",
        "lng": "121.55080318450928",
        "name": "Zhongxiao Dunhua",
        ".key": "-LoU7HQg0M1i317P75Fp"
      }, {
        "icon": "directions_transit",
        "lat": "25.041632009427325",
        "lng": "121.5437650680542",
        "name": "Zhongxiao Fuxing",
        ".key": "-LoU7Hati2BDgI9NzWgv"
      }, {
        "icon": "directions_transit",
        "lat": "25.04485911668452",
        "lng": "121.52333736419678",
        "name": "Shandao Temple",
        ".key": "-LoU7Hw4YCgQWsVhl_gr"
      }, {
        "icon": "directions_transit",
        "lat": "25.04632359924048",
        "lng": "121.51735782579635",
        "name": "Taipei main station",
        ".key": "-LoU7I5i9sBBhVjpDDUa"
      }, {
        "icon": "directions_transit",
        "lat": "25.035255306871402",
        "lng": "121.50042057037354",
        "name": "Longshan Temple",
        ".key": "-LoU7IRKn5H567pH5fsm"
      }, {
        "icon": "directions_transit",
        "lat": "25.03004482821854",
        "lng": "121.4723539352417",
        "name": "Jiangzicui",
        ".key": "-LoU7IbAMeyU1gkJXGM_"
      }, {
        "icon": "directions_transit",
        "lat": "25.023123103954475",
        "lng": "121.46806240081787",
        "name": "Xinpu",
        ".key": "-LoU7Im6qQA-drVVHCiD"
      }, {
        "icon": "directions_transit",
        "lat": "25.013828714193167",
        "lng": "121.46244049072266",
        "name": "Banqiao",
        ".key": "-LoU7Ix2Em5uUJl-ynG1"
      }, {
        "icon": "directions_transit",
        "lat": "25.0086173259166",
        "lng": "121.4593505859375",
        "name": "Fuzhong",
        ".key": "-LoU7J6pcQ05eUGqqbD1"
      }, {
        "icon": "directions_transit",
        "lat": "24.99823278108647",
        "lng": "121.45231246948242",
        "name": "Far Eastern Hospital",
        ".key": "-LoU7JHZNabZ419E1zqU"
      }, {
        "icon": "directions_transit",
        "lat": "24.985396737701528",
        "lng": "121.44875049591064",
        "name": "Haishan",
        ".key": "-LoU7JSKjMBabJxggiZs"
      }, {
        "icon": "directions_transit",
        "lat": "24.97306509458235",
        "lng": "121.44424438476562",
        "name": "Tucheng",
        ".key": "-LoU7JbzU7cg79xgxL1O"
      }, {
        "icon": "directions_transit",
        "lat": "24.966957166865082",
        "lng": "121.43621921539307",
        "name": "Yongning",
        ".key": "-LoU7JmdG3edDbh4Z81N"
      }, {
        "icon": "directions_transit",
        "lat": "24.959370465085744",
        "lng": "121.4197826385498",
        "name": "Dingpu",
        ".key": "-LoU7JxH8u3L0iOSPHMT"
      }, {
        "icon": "location_city",
        "lat": "24.140289",
        "lng": " 120.681817",
        "name": "Central District",
        ".key": "-LvFOcqSB1bkPhzZYYqp"
      }, {
        "icon": "location_city",
        "lat": "24.142769",
        "lng": " 120.694434",
        "name": "East District",
        ".key": "-LvFOd09BX1mr5ZoinPy"
      }, {
        "icon": "location_city",
        "lat": "24.143242",
        "lng": " 120.663125",
        "name": "West District",
        ".key": "-LvFOdAa3BlLAFk0UdGU"
      }, {
        "icon": "location_city",
        "lat": "24.120966",
        "lng": " 120.662462",
        "name": "South District",
        ".key": "-LvFOdLGpRD04xB1GJfK"
      }, {
        "icon": "location_city",
        "lat": "24.157113",
        "lng": " 120.683264",
        "name": "South District",
        ".key": "-LvFOdVp-XAYFC8TOsTB"
      }, {
        "icon": "location_city",
        "lat": "24.176788",
        "lng": " 120.642117",
        "name": "Xitun District",
        ".key": "-LvFOdfJ8Az6-7zx3n-Y"
      }, {
        "icon": "location_city",
        "lat": "24.147076",
        "lng": " 120.608103",
        "name": "Nantun District",
        ".key": "-LvFOdprYdnZT-Vn5iBV"
      }, {
        "icon": "location_city",
        "lat": "24.187873",
        "lng": " 120.680000",
        "name": "Beitun District",
        ".key": "-LvFOe-H561qrecJ6qHD"
      }, {
        "icon": "location_city",
        "lat": "24.253879",
        "lng": " 120.721143",
        "name": "Fengyuan District",
        ".key": "-LvFOe9qCoH8nbfXQcIP"
      }, {
        "icon": "location_city",
        "lat": "24.124376",
        "lng": " 120.716979",
        "name": "Taiping District",
        ".key": "-LvFOeKIA-I28Q7QA042"
      }, {
        "icon": "location_city",
        "lat": "24.301417",
        "lng": " 120.581549",
        "name": "Qingshui District",
        ".key": "-LvFOeUzyT74209posfp"
      }, {
        "icon": "location_city",
        "lat": "24.236905",
        "lng": " 120.586225",
        "name": "Shalu District",
        ".key": "-LvFOeeJ2HmUNjoABfMp"
      }, {
        "icon": "location_city",
        "lat": "24.224158",
        "lng": " 120.654767",
        "name": "Daya District",
        ".key": "-LvFOeohy216D1n91CJD"
      }, {
        "icon": "location_city",
        "lat": "24.310125",
        "lng": " 120.723387",
        "name": "Houli District",
        ".key": "-LvFOezYGS1oql9BnNKk"
      }, {
        "icon": "location_city",
        "lat": "24.043087",
        "lng": " 120.735368",
        "name": "Wufeng District",
        ".key": "-LvFOf92z-DH1dyrz-MM"
      }, {
        "icon": "location_city",
        "lat": "24.378721",
        "lng": " 120.645550",
        "name": "Dajia District",
        ".key": "-LvFOfJY4tXWJ1FhgfSl"
      }, {
        "icon": "location_city",
        "lat": "24.260971",
        "lng": " 120.829085",
        "name": "Dongshi District",
        ".key": "-LvFOfUAUyMlZRlStNCY"
      }, {
        "icon": "location_city",
        "lat": "24.247233",
        "lng": " 120.539674",
        "name": "Wuqi District",
        ".key": "-LvFOfdhmYvCaeLn2N-7"
      }, {
        "icon": "location_city",
        "lat": "24.136673",
        "lng": " 120.561941",
        "name": "Datu District",
        ".key": "-LvFOfoV80jrIfqrnxGo"
      }, {
        "icon": "location_city",
        "lat": "24.212960",
        "lng": " 120.706356",
        "name": "Tanzi District",
        ".key": "-LvFOfz2708_W8UycFsZ"
      }, {
        "icon": "location_city",
        "lat": "24.107623",
        "lng": " 120.637775",
        "name": "Wuri District",
        ".key": "-LvFOg8kAytiP_d-VHaq"
      }, {
        "icon": "location_city",
        "lat": "24.245893",
        "lng": " 120.682101",
        "name": "Shengang District",
        ".key": "-LvFOgal1Cpp3HwrYB5o"
      }, {
        "icon": "location_city",
        "lat": "24.210627",
        "lng": " 120.515696",
        "name": "Longjing District",
        ".key": "-LvFOglP-cseqld8JFWV"
      }, {
        "icon": "location_city",
        "lat": "24.332673",
        "lng": " 120.653710",
        "name": "Waipu District",
        ".key": "-LvFOgvvDhEkRFVL3FKA"
      }, {
        "icon": "location_city",
        "lat": "24.104688",
        "lng": " 120.680901",
        "name": "Dali District",
        ".key": "-LvFOh5KUr1csPengcOI"
      }, {
        "icon": "location_city",
        "lat": "24.322024",
        "lng": " 121.308401 ",
        "name": "Heping District",
        ".key": "-LvFOhFvcjf05_NVOiZD"
      }, {
        "icon": "location_city",
        "lat": "24.273106",
        "lng": " 120.776951",
        "name": "Shigang District",
        ".key": "-LvFOhQc7tvdj8jBkhVR"
      }, {
        "icon": "location_city",
        "lat": "24.373966",
        "lng": " 120.591849",
        "name": "Daan District",
        ".key": "-LvFOhag_VdZziDoVdgU"
      }, {
        "icon": "location_city",
        "lat": "24.188334",
        "lng": " 120.815695",
        "name": "Xinshe District",
        ".key": "-LvFOhlLZNVzCLw5n55Q"
      }, {
        "icon": "school",
        "lat": "24.179267",
        "lng": " 120.646665",
        "name": "Feng Chia University",
        ".key": "-LvFOmjDjIc5f0sI1Lh-"
      }, {
        "icon": "school",
        "lat": "24.179267",
        "lng": "120.646665",
        "name": "FCU",
        ".key": "-LvFOmjDjIc5f0sI1Lh1"
      }, {
        "icon": "school",
        "lat": "24.179267",
        "lng": "120.646665",
        "name": "FCUCLC",
        ".key": "-LvFOmjDjIc5f0sI1Lh2"
      }, {
        "icon": "school",
        "lat": "24.180316",
        "lng": " 120.604473",
        "name": "Tunghai University",
        ".key": "-LvFOmtuqtgeZJxesK71"
      }, {
        "icon": "school",
        "lat": "24.180316",
        "lng": " 120.604473",
        "name": "THU",
        ".key": "-LvFOmtuqtgeZJxesK72"
      }, {
        "icon": "school",
        "lat": "24.180316",
        "lng": " 120.604473",
        "name": "THUCLC",
        ".key": "-LvFOmtuqtgeZJxesK73"
      }, {
        "icon": "school",
        "lat": "24.122949",
        "lng": " 120.675402",
        "name": "National Chung Hsing University",
        ".key": "-LvFOn3hj4NNDzDaLBIc"
      }, {
        "icon": "school",
        "lat": "24.122949",
        "lng": " 120.675402",
        "name": "NCHU",
        ".key": "-LvFOn3hj4NNDzDaLBId"
      }, {
        "icon": "school",
        "lat": "24.122949",
        "lng": " 120.675402",
        "name": "NCHULC",
        ".key": "-LvFOn3hj4NNDzDaLBIe"
      }, {
        "icon": "school",
        "lat": "24.226038",
        "lng": " 120.577222",
        "name": "Providence University",
        ".key": "-LvFOnEKr8YuBuBwoCjV"
      }, {
        "icon": "school",
        "lat": "24.046561",
        "lng": " 120.686442",
        "name": "Asia University",
        ".key": "-LvFOnOhaFiWYeZOy5QF"
      }, {
        "icon": "school",
        "lat": "24.156480",
        "lng": " 120.680166",
        "name": "China Medical University ",
        ".key": "-LvFOnZBStk96weA9ZS8"
      }, {
        "icon": "school",
        "lat": "24.069763",
        "lng": " 120.714813",
        "name": "Chaoyang University of Technology",
        ".key": "-LvFOnikL_vZOyliPMnU"
      }, {
        "icon": "school",
        "lat": "24.143717",
        "lng": " 120.671806",
        "name": "National Taichung University of Education",
        ".key": "-LvFOntC96ATtIkdQoUw"
      }, {
        "icon": "school",
        "lat": "24.217444",
        "lng": " 120.580724",
        "name": "Hungkuang University of Science and Technology",
        ".key": "-LvFOo2Z398CK4HnU2aA"
      }, {
        "icon": "school",
        "lat": "24.144057",
        "lng": " 120.732125",
        "name": "National Chin-Yi University of Technology",
        ".key": "-LvFOoDC1va8ePYq0hKv"
      }, {
        "icon": "school",
        "lat": "24.122214",
        "lng": " 120.650498",
        "name": "Chung Shan Medical University",
        ".key": "-LvFOoNgAfJsYbJxvYIV"
      }, {
        "icon": "school",
        "lat": "24.173231",
        "lng": " 120.735701",
        "name": "Central Taiwan University of Science and Technology",
        ".key": "-LvFOoY5Ll-29lvainYQ"
      }, {
        "icon": "school",
        "lat": "24.190196",
        "lng": " 120.643785",
        "name": "Overseas Chinese University",
        ".key": "-LvFOohZmXB4EQFWbOUa"
      }, {
        "icon": "school",
        "lat": "24.150186",
        "lng": " 120.683975",
        "name": "National Taichung University of Science and Technology",
        ".key": "-LvFOorzntmsZB7_wd_f"
      }, {
        "icon": "school",
        "lat": "24.148718",
        "lng": " 120.687659",
        "name": "National Taiwan University of Sport",
        ".key": "-LvFOp1Ttn7VxXE2Neh2"
      }, {
        "icon": "school",
        "lat": "24.138020",
        "lng": " 120.609580",
        "name": "LING TUNG UNIVERSITY",
        ".key": "-LvFOpC04wUR8qtOrmbM"
      }, {
        "icon": "school",
        "lat": "24.095478",
        "lng": " 120.713839",
        "name": "Hsiuping University of Science and Technology",
        ".key": "-LvFOpMSrIkFgZ9RSFiA"
      }, {
        "icon": "local_dining",
        "lat": "24.178848",
        "lng": " 120.646460",
        "name": "Feng Chia Night Market",
        ".key": "-LvFOsJirdQ6tkENssmY"
      }, {
        "icon": "local_dining",
        "lat": "24.149201",
        "lng": " 120.685017",
        "name": "Yizhong Shopping District",
        ".key": "-LvFOsUQbckFIDJaVUmr"
      }, {
        "icon": "photo_filter",
        "lat": "24.162708",
        "lng": " 120.640314",
        "name": "National Taichung Theater",
        ".key": "-LvFOsynxJezfLa7UIuk"
      }, {
        "icon": "photo_filter",
        "lat": "24.157292",
        "lng": " 120.666082",
        "name": "National Museum of Natural Science",
        ".key": "-LvFOt8ElpHN7y2aMIMh"
      }, {
        "icon": "photo_filter",
        "lat": "24.165807",
        "lng": " 120.639666",
        "name": "Maple Garden",
        ".key": "-LvFOtIfkbSacg_YQG45"
      }, {
        "icon": "photo_filter",
        "lat": "24.148408",
        "lng": " 120.664766",
        "name": "Calligraphy Greenway",
        ".key": "-LvFOtTAWdvhEC_jexSI"
      }, {
        "icon": "photo_filter",
        "lat": "24.144846",
        "lng": " 120.684581",
        "name": "Taichung Park",
        ".key": "-LvFOtcdlU9AwVw-Ogka"
      }, {
        "icon": "photo_filter",
        "lat": "24.154334",
        "lng": " 120.689989",
        "name": "Taichung Confucius Temple",
        ".key": "-LvFOtnKQSdmP4_A0G3f"
      }, {
        "icon": "directions_transit",
        "lat": "24.137764",
        "lng": " 120.686558",
        "name": "Taichung Railway Station",
        ".key": "-LvFOvHcTuYTo0LpdzMZ"
      }, {
        "icon": "directions_transit",
        "lat": "24.181360",
        "lng": " 120.619417",
        "name": "Zhonggang New Community",
        ".key": "-LvFOvSIeSt8f53P7gly"
      }, {
        "icon": "directions_transit",
        "lat": "24.167584",
        "lng": " 120.637684",
        "name": "ChaoMa",
        ".key": "-LvFOvbpWJgUfiQhRYnx"
      }, {
        "icon": "directions_transit",
        "lat": "24.182508",
        "lng": " 120.594323",
        "name": "Dong Hai Bie Shu Shopping District",
        ".key": "-LvFOvmU2MzMBj26aVLP"
      }, {
        "icon": "directions_transit",
        "lat": "24.165954",
        "lng": " 120.643645",
        "name": "Shinkong Mitsukoshi / Top City Dept. Store",
        ".key": "-LvFOvwxJzXRmYc2oL8D"
      }, {
        "icon": "directions_transit",
        "lat": "24.164414",
        "lng": " 120.646897",
        "name": "Taichung City Hall",
        ".key": "-LvFOw6pmJ5zxQOjdij5"
      }, {
        "icon": "directions_transit",
        "lat": "24.155302",
        "lng": " 120.661776",
        "name": "Kuang San SOGO Department",
        ".key": "-LvFOwHYswIgTpGQkviR"
      }, {
        "icon": "directions_transit",
        "lat": "24.158584",
        "lng": " 120.666992",
        "name": "Botanical Garden",
        ".key": "-LvFOwS4Gf8aXpVb43St"
      }, {
        "icon": "directions_transit",
        "lat": "24.153052",
        "lng": " 120.667377",
        "name": "Chung-Cheng Elementary School",
        ".key": "-LvFOwbwaLS6hFyFs1Mb"
      }, {
        "icon": "directions_transit",
        "lat": "24.15431167",
        "lng": " 120.6467467",
        "name": "Wenxin-Shizheng Intersection",
        ".key": "-LvFOwmRqYc7yUCBqexR"
      }, {
        "icon": "location_city",
        "lat": "24.140289",
        "lng": " 120.681817",
        "name": "Taichung",
        ".key": "-LvFQQi1gZkdDe4Qo9u3"
      }
    ],
  }),

  methods: {
    performSearch() {
      dataLayer.push({
        'event': 'lpSearch'
      })
    },
    sendForm(){
      dataLayer.push({
        'event':'askHelp'
      })
      // console.log(this.user);
      this.sent=true
      let admin={
        email:'hello@myroomabroad.com',
        name:'WAKE UP :D!',
        userName:'WAKE UP! :D'
      }
      let user=this.$store.state.auth.user
      // let info=this.url
      console.log(this.url, user);

      let text="Dear "+this.$store.state.auth.user.userName+"<br>Thanks a lot for putting your trust in us! We will help you in any way we can. <br>Could you please confirm you are looking for this: <br>Listing Type: "+this.type+"<br>Max Budget: "+this.price+'<br>Move in date: '+this.mid+"<br>Area: "+this.location.name+"<br><br>If you have any other specific requests, please let us know. <br>In meanwhile we recommend you to take a look at our website: "+this.url+"<br> We wish you a wonderful day,<br>Your My Room Abroad Team"
      let info={
        text:text,
        subject:"Please confirm your Help Request on My Room Abroad"
      }
      let adminText="Helloooo! <br>"+this.$store.state.auth.user.userName +" ("+this.$store.state.auth.user.email+") requested help <BR>here's all the info:<br>Listing Type: "+this.type+"<br>Max Budget: "+this.price+'<br>Move in date: '+this.mid+"<br>Area: "+this.location.name+"<br>URL:"+this.url
      let info2={
        text:adminText,
        subject:this.$store.state.auth.user.userName +" ("+this.$store.state.auth.user.email+") requested help"
      }
      console.log(info);
      AWSSES.sendPlainText(admin, info2)
      AWSSES.sendPlainText(user, info)

    }

  },
  computed: {
    url() {
      let params = "/search"
      let query = ""
      if (this.type == 'apartment') {
        params = '/aptSearch'
      }
      if (this.location != "") {
        query = "?name=" + this.location.name + '&lat=' + this.location.lat + '&lng=' + this.location.lng
      } else {
        query = "?name="
      }
      if (this.price != "") {
        query = query + '&price=' + this.price
      }
      if (this.mid != "") {
        query = query + '&mid=' + this.mid
      }
      return params + query
    },
    dateMenu: {
      get() {
        if (this.pDateMenu != null) {
          return this.pDateMenu
        } else {
          return false
        }
      },
      set(val) {
        console.log('setDate Menu');
        this.$emit('changeDateMenu', val)
      }
    }
  },

}
</script>
<style lang="scss" scoped>
@media only screen and (min-width:600px) {
    .sizes {
        width: 35vw;
    }
}

@media only screen and (max-width:600px) {}
</style>
