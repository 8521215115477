<template>
  <div id="">
    <v-layout wrap pa-2>
      <v-flex xs12 class="px-2">
        <h2>台北最棒的共享公寓!</h2>
      </v-flex>
      <v-flex xs12 class="px-2">
        我們在台北有超過2000個最棒的公寓等著你!
      </v-flex>
      <v-flex >
        <div class="noScrollbar" style="max-width:95vw; overflow-x: scroll;">
          <listingCards  :listings="rooms"></ListingCards>
        </div>

      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import ListingCards from './ListingCards.vue'
export default {
  name: "",
  data: () => ({
    url:'/aptSearch?propType=wholeApts',
    rooms:[
      {
        url:'/details/-LQDiSudP6n94poSkPI6/',
        img:"-LOu1ubbI4O3cvMSox2o/jvvpvnqxmmcmacss2mjl",
        title:'有陽台的明亮房間，近台師大跟台電大樓捷運站',
        price:"TW$ 10500/月",
        area:"台電大樓站",
        nextAv:"6月30日"
      },{
        url:'/details/-Liw1E8N55wDJ94dMIq_/',
        img:"-LOufGM14aDe3Q8faEOl/qpymrhwf5wtlig4lkexq",
        title:'簡約設計風，靠近台大、台科大，也離政大不遠',
        price:"TW$ 10500/月",
        area:"大安區",
        nextAv:"6月30日"
      },{
        url:'/details/-Ldh3FcNq_BRRibZdZs0/',
        img:"-LOufGM14aDe3Q8faEOl/i49tqm7dpqv7vpefvmgt",
        title:'有設計感的雙人床房，近台電大樓捷運站',
        price:"TW$ 12500/月",
        area:"台電大樓站",
        nextAv:"6月30日"
      },{
        url:'/details/-LdgZYs9jn0OFlq10mRY/',
        img:"-LOufGM14aDe3Q8faEOl/fj63tpokxq79o39aplze",
        title:'有陽台的明亮房間，近台師大',
        price:"TW$ 12500/月",
        area:"台電大樓站",
        nextAv:"6月30日"
      }
    ]
  }),
  methods: {
    showAllClick() {
      dataLayer.push({
        'event':'showAllClicked'
      })
    }
  },
  components: {
    ListingCards
  }
}
</script>
<style lang="scss" scoped>
.noScrollbar::-webkit-scrollbar {
  display: none;
}
.noScrollbar{
  -ms-overflow-style:none;
}

</style>
