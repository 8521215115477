<template>
  <div id="">
    <v-layout wrap pa-2>
      <v-flex xs12 class="px-2">
        <h2>What do tenants say about the rooms in Taipei</h2>
      </v-flex>
      <v-flex xs12 class="px-2">
        Here are the latest reviewed rooms on our platform.
      </v-flex>
      <v-flex >
        <div class="noScrollbar" style="max-width:95vw; overflow-x: scroll;">
          <ReviewCards  :listings="rooms"></ReviewCards>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import ReviewCards from './ReviewCards.vue'
export default {
  name: "",
  data: () => ({
    rooms:[
      {
        url:'/details/-Liw1E8N55wDJ94dMIq_/',
        img:"-LOufGM14aDe3Q8faEOl/uezqpe9nnpcdpgc3nrvn",
        stars:5,
        comment:'Everything was really good! The room is comfortable, landlord is nice and kind. Housemates are also nice. There are many shops around; convenient stores; supermarkets, etc. The most important thing is that it’s near MRT Station.',
        revName:"Gabriela",
        revPhoto:"reviewers/Gabriela",
        revLoc:"Colombia",
      },
      {
        url:'/details/-LTpjh8c56hONQAyBmaV/',
        img:"-LOufGM14aDe3Q8faEOl/u4otbshtiympiikkfofb",
        stars:5,
        comment:'Very near NTU, so convenient. 5-min walk to MRT technology building. Around the area has many restaurant. Inside the room, it got a big kitchen with gas with pots and pans. Recommend if you want to cook your own food.',
        revName:"Pimisiri",
        revPhoto:"reviewers/Pimsiri",
        revLoc:"Thailand",
      },
      {
        url:'/details/-LaTA9IaBiDuXRdhwoDR/',
        img:"/-LVbNnvzMFf2fOojbxl_/sml5k0843nmfziicvlsu",
        stars:5,
        comment:'Good location, awesome flatmates (4 exchange students) and the landlord was really nice and helpful! All in all I had a great time there!',
        revName:"Fabian",
        revPhoto:"reviewers/Fabian",
        revLoc:"Austria",
      },
      {
        url:'/details/-LPQ587hI1elb7-ZOWh2/',
        img:"-LOqFfd0QOKVbDjOMKUq/iyxtto81khqxswarxrc0",
        stars:5,
        comment:'Room and the apartment were exactly as pictured in the photos, it was really clean and the roomates were awesome! The flat is well located and the people from My Room Abroad were really kind and helped us translate any request to the landlord. I can really recommend this room!',
        revName:"Ines",
        revPhoto:"reviewers/Ines",
        revLoc:"France",
      },
      {
        url:'/details/-Lv54RkxA2CIj3s4b7zG/',
        img:"-LrS6hGp1ZNkcyiK5ssK/figk5y9wbgqebf8zydrl",
        stars:5,
        comment:'The flat was really clean and the landlord is the nicest, really helpful! There is a cleaning service, a big equipped kitchen and the housemates were respectfull! Good option to live in Taipei I can really recommend it!',
        revName:"Laura",
        revPhoto:"reviewers/Laura",
        revLoc:"Canada",
      },
      {
        url:'/details/-LQODg8O1Qnc-nD0mqva/',
        img:"-LOu1ubbI4O3cvMSox2o/lrn76trynwjqi5uzrbgz",
        stars:5,
        comment:'Fantastic location, especially for any university related things, and living close from a great restaurant and night market makes this a super good Taipei experience!',
        revName:"Ruben",
        revPhoto:"reviewers/Ruben",
        revLoc:"Netherlands",
      },
    ]
  }),

  components: {
    ReviewCards
  }
}
</script>
<style lang="scss" scoped>
.noScrollbar::-webkit-scrollbar {
  display: none;
}
.noScrollbar{
  -ms-overflow-style:none;
}

</style>
