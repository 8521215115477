<template>
  <div id="">
    <v-layout wrap pa-2>
      <v-flex xs12 class="px-2">
        <h2>What do tenants say about the accommodation in Taipei</h2>
      </v-flex>
      <v-flex xs12 class="px-2">
        Here are the latest reviewed accommodation on our platform.
      </v-flex>
      <v-flex >
        <div class="noScrollbar" style="max-width:95vw; overflow-x: scroll;">
          <ReviewCards  :listings="rooms"></ReviewCards>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import ReviewCards from './ReviewCards.vue'
export default {
  name: "",
  data: () => ({
    rooms:[
      {
        url:'/apartmentDetails/-LQbdOmq5QrTdne_RbrN/apartment/',
        img:"-LOqEaHmz54W80lAcNlK/ne7z9fmhq3uxixek5qox",
        stars:5,
        comment:'We rented this apartment together with 4 friends! We had a great time!  The apartment was good and the My Room Abroad team was really helful to help us commmunicate with the landlord when we had questions!',
        revName:"Pol",
        revPhoto:"reviewers/Pol",
        revLoc:"Barcelona, Spain",
      },
      {
        url:'/apartmentDetails/-LfxWfkhu9lFNgs1OUBB/rooms/',
        img:"-LVbNnvzMFf2fOojbxl_/x4irc7ijna7nujmluqdy",
        stars:5,
        comment:"Lovely place, lovely neighbors, lovely customer service by My Room Abroad and the landlord. This website helped me a lot to find a perfect place for me to live in Taipei and I'm so grateful for that.",
        revName:"Lynda",
        revPhoto:"reviewers/Lynda",
        revLoc:"PA, United States",
      },
      {
        url:'/apartmentDetails/-LOqKLdKPdxtgxG5fpUS/rooms/',
        img:"-LOqFfd0QOKVbDjOMKUq/pcxf9gvxzp5ubff0maqz",
        stars:5,
        comment:"Great apartment. Clean and as expected from the pictures on the website!! The landlord was always there when needed with the help of My Room Abroad to translate our requests! ",
        revName:"Lynn",
        revPhoto:"reviewers/Lynn",
        revLoc:"Cape Town, South Africa",
      },
      {
        url:'/apartmentDetails/-Lfs0KpfdRaQhE0EmjGw/rooms/',
        img:"-Lfs-XAhAZbcQgXNbekF/c7dlaldoulvxshwribto",
        stars:5,
        comment:'Loved the place! Great location! Really convenient and dynamic neibourhood!',
        revName:"Ludovic",
        revPhoto:"reviewers/Ludovic",
        revLoc:"Paris, France",
      },
      {
        url:'/details/-LQODg8O1Qnc-nD0mqva/',
        img:"-LOu1ubbI4O3cvMSox2o/lrn76trynwjqi5uzrbgz",
        stars:5,
        comment:'Fantastic location, especially for any university related things, and living close from a great restaurant and night market makes this a super good Taipei experience!',
        revName:"Ruben",
        revPhoto:"reviewers/Ruben",
        revLoc:"Netherlands",
      },

      // studios
      {
        url:'/apartmentDetails/-LujkWrzTuM6NP6VGRN0/apartment',
        img:"-LOu1ubbI4O3cvMSox2o/cwzqvnrehqwcy3ypki6f",
        stars:5,
        comment:'Our stay in Taipei was outstanding! The place is beautiful and comfy, safe and clean, and wonderfully situated in a dynamic neigbourhood full of local shops and restaurants! ',
        revName:"Ludivine",
        revPhoto:"reviewers/Ludivine",
        revLoc:"Montreal, Cananda",
      },
      {
        url:'/studioDetails/-LfFXdeAVDzzMnfuTlZd/',
        img:"-Lf4pMi7QapdpRacC7XJ/tihodgd2kirmpfyqh93c",
        stars:5,
        comment:'The landlord was very welcoming and communication through My Room Abroad was very helpful! No complaints whatsoever. The apartment was as expected from the pictures, clean and greatly located nearby Guting MRT station!',
        revName:"James",
        revPhoto:"reviewers/James",
        revLoc:"CA, United States",
      },
      {
        url:'/studioDetails/-LpCqcTIWLxK_uEbI3-9/',
        img:"-LpCq_ZZLDTGNzLKgfMs/hfbidoxuwdvk9auxyh5w",
        stars:5,
        comment:'I Recommend it! The landlord is really nice and the flat is in a really nice area. We had everything we needed there.',
        revName:"Chan Mei",
        revPhoto:"reviewers/Debby",
        revLoc:"HK, Hong Kong",
      },
      {
        url:'/studioDetails/-M-n1b7KvQwUJ5aYi7MA',
        img:"-Ls6C240wY7HZbQu9g42/whsto4nmdtknyvmcrfci",
        stars:5,
        comment:'We had a wonderful stay. Everything one would need was provided, and the space itself is so charming and lovely. ',
        revName:"Charles",
        revPhoto:"reviewers/Charles",
        revLoc:"Glasgow, United Kingdom",
      },
      {
        url:'/studioDetails/-Ll-d5xSvEBq00cxzrSH/',
        img:"-Ll-d2ADnD-MAad6UwVr/qiwrvh8t7luriuhr293p",
        stars:5,
        comment:'The place was clean and closeby MRT, the landlord was kind and respectful! ',
        revName:"Nobukoyo",
        revPhoto:"reviewers/Nobukoyo",
        revLoc:"Tokyo, Japan",
      },
      {
        url:'/studioDetails/-LikIz7iHCJwpaPairjV/',
        img:"-Leo36rw_9jpj0wgyJT-/ji3v0iwivwauucg4yo5o",
        stars:5,
        comment:"Communication with the landlord prior to my arrival was seamless thank to My Room Abroad. The studio was convenient and the landlord was accommodating and responsive!",
        revName:"Joanna",
        revPhoto:"reviewers/Joanna",
        revLoc:"Gothenburg, Sweden",
      },

      // Apartments
      {
        url:'/apartmentDetails/-LQbdOmq5QrTdne_RbrN/apartment/',
        img:"-LOqEaHmz54W80lAcNlK/ne7z9fmhq3uxixek5qox",
        stars:5,
        comment:'We rented this apartment together with 4 friends! We had a great time!  The apartment was good and the My Room Abroad team was really helful to help us commmunicate with the landlord when we had questions!',
        revName:"Pol",
        revPhoto:"reviewers/Pol",
        revLoc:"Barcelona, Spain",
      },
      {
        url:'/apartmentDetails/-LfxWfkhu9lFNgs1OUBB/rooms/',
        img:"-LVbNnvzMFf2fOojbxl_/x4irc7ijna7nujmluqdy",
        stars:5,
        comment:"Lovely place, lovely neighbors, lovely customer service by My Room Abroad and the landlord. This website helped me a lot to find a perfect place for me to live in Taipei and I'm so grateful for that.",
        revName:"Lynda",
        revPhoto:"reviewers/Lynda",
        revLoc:"PA, United States",
      },
      {
        url:'/apartmentDetails/-LOqKLdKPdxtgxG5fpUS/rooms/',
        img:"-LOqFfd0QOKVbDjOMKUq/pcxf9gvxzp5ubff0maqz",
        stars:5,
        comment:"Great apartment. Clean and as expected from the pictures on the website!! The landlord was always there when needed with the help of My Room Abroad to translate our requests! ",
        revName:"Lynn",
        revPhoto:"reviewers/Lynn",
        revLoc:"Cape Town, South Africa",
      },
      {
        url:'/apartmentDetails/-Lfs0KpfdRaQhE0EmjGw/rooms/',
        img:"-Lfs-XAhAZbcQgXNbekF/c7dlaldoulvxshwribto",
        stars:5,
        comment:'Loved the place! Great location! Really convenient and dynamic neibourhood!',
        revName:"Ludovic",
        revPhoto:"reviewers/Ludovic",
        revLoc:"Paris, France",
      },
      {
        url:'/details/-LwpluVZ9KcazeRiqRsW',
        img:"-LfXbyi4E5HfERRvQ9JQ/zymtum1gpphotwsguzxi",
        stars:5,
        comment:'A great place to stay at! Convenient, practical, and well equipped. The landlady is very responsive, responsible and treats his tenants with great respect and attention. She even left us some typical Taiwanese snacks! Place is comfortable and spacious, we were not disappointed!',
        revName:"Joshua",
        revPhoto:"reviewers/Joshua",
        revLoc:"Sydney, Australia",
      },
      {
        url:'/details/-LQODg8O1Qnc-nD0mqva/',
        img:"-LOu1ubbI4O3cvMSox2o/lrn76trynwjqi5uzrbgz",
        stars:5,
        comment:'Fantastic location, especially for any university related things, and living close from a great restaurant and night market makes this a super good Taipei experience!',
        revName:"Ruben",
        revPhoto:"reviewers/Ruben",
        revLoc:"Netherlands",
      },


    ]
  }),

  components: {
    ReviewCards
  }
}
</script>
<style lang="scss" scoped>
.noScrollbar::-webkit-scrollbar {
  display: none;
}
.noScrollbar{
  -ms-overflow-style:none;
}

</style>
