<template>
  <div id="">
    <v-layout wrap pa-2>
      <v-flex xs12 class="px-2">
        <h2>Some of the best rooms in Taipei</h2>
      </v-flex>
      <v-flex xs12 class="px-2">
        We have listed over 2000 of the best rooms available in Taipei.
      </v-flex>
      <v-flex >
        <div class="noScrollbar" style="max-width:95vw; overflow-x: scroll;">
          <listingCards  :listings="rooms"></ListingCards>
        </div>
        <v-layout pa-3>
          <a color="primary" class="subheading" @click="showAllClick()" :href="url">Show All (2000+)</a>
        </v-layout>

      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import ListingCards from './ListingCards.vue'
export default {
  name: "",
  data: () => ({
    url:'/search?propType=room',
    rooms:[
      {
        url:'/details/-LQOAdyVkvXrqpK5OoX5/',
        img:"-LOu1ubbI4O3cvMSox2o/azjhon2ixerxd3zccjbg",
        title:'Stylish elevator room @Gongguan MRT',
        price:"TW$ 14000/Month",
        area:"Gongguan",
        nextAv:"March 1st"
      },{
        url:'/details/-LSxJf_ZY08sSArBUUVH/',
        img:"-LSOHT4wFtvKabn9ZQTk/ukgvactogmfj2cqeuva5",
        title:'Beautiful room with private bathroom in Ximen',
        price:"TW$ 16500/Month",
        area:"Ximen",
        nextAv:"March 1st"
      },{
        url:'/details/-LeWEqOJV2CdAyWUXX8d/',
        img:"-LePHAxaYK7ieH64tEe1/gkl7vwgxq8xppuajwdaj",
        title:'Delicate & Cosy room',
        price:"TW$ 10000/Month",
        area:"Da'an",
        nextAv:"March 1st"
      },{
        url:'/details/-LYAhpVcbt0k5ybqITEC/',
        img:"-LOqmuL1fdmuyvZRaYZj/khyvfrdhvndt3fe3vqyd",
        title:'Bright Double Bedroom with view on NTNU MTC',
        price:"TW$ 10000/Month",
        area:"Da'an",
        nextAv:"March 1st"
      },{
        url:'/details/-LRlNN5KPXgEOgFLmLaZ/',
        img:"-LR6Gn5fs7LgX_DNB9BT/cznsrnaiidrsrexttnlc",
        title:'Industrial design Single bedroom',
        price:"TW$ 16000/Month",
        area:"Da'an",
        nextAv:"March 1st"
      },{
        url:'/details/-LQgpqO3U8bLs7Bks_oQ/',
        img:"-LOufGM14aDe3Q8faEOl/fp2hyra8i6suuwkbaub5",
        title:"Spacious Ensuite Bedroom near Da'an ",
        price:"TW$ 18000/Month",
        area:"Da'an",
        nextAv:"March 1st"
      },{
        url:'/details/-LQbatskIMnqpBTdwPxt/',
        img:"-LOu1ubbI4O3cvMSox2o/h2pmphhpsiwihyw7qgjy",
        title:'Stylish room near NTU NTUST',
        price:"TW$ 17500/Month",
        area:"Guting",
        nextAv:"March 1st"
      },{
        url:'/details/-LQI9GJKSGfLyrToPi9w/',
        img:"-LOu1ubbI4O3cvMSox2o/qvgh7cn9guyzphz0ro0q",
        title:'NTNU stylish studio @ Taipower Building MRT 5 min',
        price:"TW$ 18500/Month",
        area:"Da'an",
        nextAv:"March 1st"
      },{
        url:'/details/-LkLnVINW47evSaC4BW1/',
        img:"-LOu1ubbI4O3cvMSox2o/wer9lsk6ihmbkhbnk1yv",
        title:'Stylish room near NTU NTUST @Technology building MRT',
        price:"TW$ 12500/Month",
        area:"Da'an",
        nextAv:"March 1st"
      }
    ]
  }),
  methods: {
    showAllClick() {
      dataLayer.push({
        'event':'showAllClicked'
      })
    }
  },
  components: {
    ListingCards
  }
}
</script>
<style lang="scss" scoped>
.noScrollbar::-webkit-scrollbar {
  display: none;
}
.noScrollbar{
  -ms-overflow-style:none;
}

</style>
