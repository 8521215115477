<template>
  <div id="">
    <v-layout style="width:360vw; margin:auto; max-width:1050px">

    <v-card flat style="margin:auto" @click.native="cardClicked(listings[el].url)" :href="listings[el].url" :id="'lpBestListingCard'+listings[el].url" v-for="el in selection" class="pa-2" max-width="262" width="90vw" :key="'lpBestListingCard'+listings[el].url">
      <v-img
        height='157'
        :src="'https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_157/v1539854631/'+listings[el].img"
      >
      </v-img>
      <v-card-title class="max-title-height">
        <div class="block-with-text">
          {{listings[el].title}}
        </div>
      </v-card-title>
      <v-card-text>
        <div class="">
          {{listings[el].price}}
        </div>
        <div class="">
          {{listings[el].area}}
        </div>
      </v-card-text>
    </v-card>
  </v-layout>

  </div>
</template>
<script>
export default {
  name: "",
  props:['listings', 'url'],
  data: () => ({

  }),
  methods: {
    cardClicked(url) {
      console.log('push bestListingClicked');
      dataLayer.push({
        'event':'bestListingClicked',
        'url':url,
      })

    }
  },
  computed:{
    selection(){
      var arr = [];
      while(arr.length < 4){
          var r = Math.floor(Math.random() * this.listings.length)
          if(arr.indexOf(r) === -1) arr.push(r);
      }
      console.log(arr)
      return arr
    },
  }
}
</script>
<style lang="scss" scoped>
.max-title-height{
  height:5em;
}
.block-with-text {
  display: -webkit-box;
  max-width: 100%;
  height: 2.5em; /* Fallback for non-webkit */
  line-height: 1.3em;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
