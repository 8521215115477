<template>
  <div id="">
    <v-layout wrap pa-2>
      <v-flex xs12 class="px-2">
        <h2>來自台灣房客的感想</h2>
      </v-flex>
      <v-flex xs12 class="px-2">
        這些是網站上最新的公寓評價
      </v-flex>
      <v-flex >
        <div class="noScrollbar" style="max-width:95vw; overflow-x: scroll;">
          <ReviewCards  :listings="rooms"></ReviewCards>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import ReviewCards from './ReviewCardsZH.vue'
export default {
  name: "",
  data: () => ({
    rooms:[
      {
        url:'/apartmentDetails/-LQbdOmq5QrTdne_RbrN/apartment/',
        img:"-LOqEaHmz54W80lAcNlK/ne7z9fmhq3uxixek5qox",
        stars:5,
        comment:'我們四個朋友一起租了一間整層公寓，位置方便且價格划算。這對於不想住學校宿舍的我們來說幫助很大，並且My Room Abroad的團隊成員很擅長協助我們跟房東的溝通和協商~讓我們在租房期間很安心 :)',
        revName:"Ann",
        revLoc:"台北",
      },
     {
        url:'/apartmentDetails/-Lfs0KpfdRaQhE0EmjGw/rooms/',
        img:"-Lfs-XAhAZbcQgXNbekF/c7dlaldoulvxshwribto",
        stars:5,
        comment:'因為工作關係要在台北待三個月，當初一直找不到可以短租的房間。後來朋友介紹了這個網站，沒多久就幫我找到可以住三個月的房間，並且條件比其他地方看到的還好，謝謝你們哦。',
        revName:"威廷",
        revLoc:"台中",
      },
      {
        url:'/details/-LwpluVZ9KcazeRiqRsW',
        img:"-LfXbyi4E5HfERRvQ9JQ/zymtum1gpphotwsguzxi",
        stars:5,
        comment:'上學期沒抽到宿舍，必須自己找房。一開始找了蠻久的，都沒看到喜歡的。後來得知My Room Abroad有可以接受半年短租的房間，就選他們了。住過半年後，我覺得房東很棒、房間位置也很好，因此決定再住半年，很開心當初有使用你們的服務!',
        revName:"Kelly",
        revLoc:"高雄",
      },
    ]
  }),

  components: {
    ReviewCards
  }
}
</script>
<style lang="scss" scoped>
.noScrollbar::-webkit-scrollbar {
  display: none;
}
.noScrollbar{
  -ms-overflow-style:none;
}

</style>
