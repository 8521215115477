<template>
  <div id="">
    <v-layout wrap pa-2>
      <v-flex xs12 class="pa-2">
        <h2>Why renting with My Room Abroad</h2>
      </v-flex>
      <v-flex >
        <div class="noScrollbar" style="max-width:95vw; overflow-x: scroll;">
          <v-layout style="width:180vw; max-width:1050px; margin:auto">

          <v-card flat v-for="(vp, x) in vps" :key="'vp - '+x" style="margin:auto" class="pa-2" max-width="350px" width="60vw">
            <!-- <v-img
              class="ml-1"
              height='35'
              width='50'
              :src="'https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_25/v1539854631/'+vp.icon"
            >
            </v-img> -->
            <v-icon large color="primary">{{vp.icon}}</v-icon>
            <v-card-title class="pl-1">
                {{vp.title}}
            </v-card-title>
            <v-card-text class="pl-1">
              {{vp.text}}
            </v-card-text>
          </v-card>
        </v-layout>

        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  name: "",
  data: () => ({
    vps:[
      {
        icon:"forum",
        title:"English Speaking support",
        text:"Can't communicate well with your landlord? Not anymore! My Room Abroad's English speaking support is happy to help you with any issues you have with landlord.",
      },
      {
        icon:"verified_user",
        title:"Secure Online Reservation",
        text:"Book your room online without having to visit it in person. Thanks to our 24-hour guarantee you can securely book your room and get refunded if the description doesn't match reality.",
      },
      {
        icon:"payment",
        title:"Automatic Rent Payment",
        text:"Forget about late fees and stop worrying about paying your rent on time. Thanks to My Room Abroad's automatic rent payment system, the only thing you need to worry about is enjoying life",
      },
    ]
  }),
}
</script>
<style lang="scss" scoped>
.noScrollbar::-webkit-scrollbar {
  display: none;
}
.noScrollbar{
  -ms-overflow-style:none;
}

</style>
