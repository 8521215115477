<template>
  <div id="">
    <v-layout wrap pa-2>
      <v-flex xs12 class="px-2">
        <h2>What do tenants say about the studios in Taipei</h2>
      </v-flex>
      <v-flex xs12 class="px-2">
        Here are the latest reviewed studios on our platform.
      </v-flex>
      <v-flex >
        <div class="noScrollbar" style="max-width:95vw; overflow-x: scroll;">
          <ReviewCards  :listings="rooms"></ReviewCards>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import ReviewCards from './ReviewCards.vue'
export default {
  name: "",
  data: () => ({
    rooms:[
      {
        url:'/apartmentDetails/-LujkWrzTuM6NP6VGRN0/apartment',
        img:"-LOu1ubbI4O3cvMSox2o/cwzqvnrehqwcy3ypki6f",
        stars:5,
        comment:'Our stay in Taipei was outstanding! The place is beautiful and comfy, safe and clean, and wonderfully situated in a dynamic neigbourhood full of local shops and restaurants! ',
        revName:"Ludivine",
        revPhoto:"reviewers/Ludivine",
        revLoc:"Montreal, Cananda",
      },
      {
        url:'/studioDetails/-LfFXdeAVDzzMnfuTlZd/',
        img:"-Lf4pMi7QapdpRacC7XJ/tihodgd2kirmpfyqh93c",
        stars:5,
        comment:'The landlord was very welcoming and communication through My Room Abroad was very helpful! No complaints whatsoever. The apartment was as expected from the pictures, clean and greatly located nearby Guting MRT station!',
        revName:"James",
        revPhoto:"reviewers/James",
        revLoc:"CA, United States",
      },
      {
        url:'/studioDetails/-LpCqcTIWLxK_uEbI3-9/',
        img:"-LpCq_ZZLDTGNzLKgfMs/hfbidoxuwdvk9auxyh5w",
        stars:5,
        comment:'I Recommend it! The landlord is really nice and the flat is in a really nice area. We had everything we needed there.',
        revName:"Chan Mei",
        revPhoto:"reviewers/Debby",
        revLoc:"HK, Hong Kong",
      },
      {
        url:'/studioDetails/-M-n1b7KvQwUJ5aYi7MA',
        img:"-Ls6C240wY7HZbQu9g42/whsto4nmdtknyvmcrfci",
        stars:5,
        comment:'We had a wonderful stay. Everything one would need was provided, and the space itself is so charming and lovely. ',
        revName:"Charles",
        revPhoto:"reviewers/Charles",
        revLoc:"Glasgow, United Kingdom",
      },
      {
        url:'/studioDetails/-Ll-d5xSvEBq00cxzrSH/',
        img:"-Ll-d2ADnD-MAad6UwVr/qiwrvh8t7luriuhr293p",
        stars:5,
        comment:'The place was clean and closeby MRT, the landlord was kind and respectful! ',
        revName:"Nobukoyo",
        revPhoto:"reviewers/Nobukoyo",
        revLoc:"Tokyo, Japan",
      },
      {
        url:'/studioDetails/-LikIz7iHCJwpaPairjV/',
        img:"-Leo36rw_9jpj0wgyJT-/ji3v0iwivwauucg4yo5o",
        stars:5,
        comment:"Communication with the landlord prior to my arrival was seamless thank to My Room Abroad. The studio was convenient and the landlord was accommodating and responsive!",
        revName:"Joanna",
        revPhoto:"reviewers/Joanna",
        revLoc:"Gothenburg, Sweden",
      },
    ]
  }),

  components: {
    ReviewCards
  }
}
</script>
<style lang="scss" scoped>
.noScrollbar::-webkit-scrollbar {
  display: none;
}
.noScrollbar{
  -ms-overflow-style:none;
}

</style>
