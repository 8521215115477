<template>
<div id="" class="justify">
  <div class="search-row">
    <Navbar class="hidden-sm-and-up"></Navbar>
    <NavbarTransp class="hidden-xs-only"></NavbarTransp>

    <v-layout pa-2 py-3 wrap justify-center>
      <v-flex xs12 sm10 md8>
        <v-layout justify-center wrap>

          <h1 class="display-3 blog-title text-xs-center">
            Welcome to Taiwan
          </h1>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs12 sm4 class="text-xs-center">
            <v-btn color="white" href="#tabs" @click="tab=0" large flat>Work</v-btn>
          </v-flex>
          <v-flex xs12 sm4 class="text-xs-center">
            <v-btn color="white" @click="tab=1" href="#tabs" large flat>Study</v-btn>
          </v-flex>
          <v-flex xs12 sm4 class="text-xs-center">
            <v-btn color="white" @click="tab=2" href="#tabs" large flat>Visit</v-btn>
          </v-flex>
        </v-layout>

      </v-flex>
    </v-layout>
  </div>
  <v-layout pa-2 py-3 wrap justify-center>
    <v-flex xs12 sm10 md8>
      <v-tabs fixed-tabs left v-model="tab" id="tabs">
        <v-tab :key="1">
          Work
        </v-tab>
        <v-tab :key="2">
          Study
        </v-tab>
        <v-tab :key="0">
          Visit
        </v-tab>
      </v-tabs>
      <v-tabs-items full-width v-model="tab">
        <v-tab-item lazy :key="0">
          <v-layout wrap justify-center ma-3>
            <v-flex xs12>
              <h2 class="headline py-2">Your guide to <strong>working</strong> and <strong>moving</strong> to Taiwan</h2>
              <span>
                Taiwan is a beautiful, welcoming country full of wonderful hidden gems! You won’t be disappointed.
                <br>This guide was made to make your move to this wonderful country easier!
              </span>
            </v-flex>
          </v-layout>
          <v-layout wrap my-4>
            <v-flex xs12 sm6 class="pa-2">
              <v-layout wrap style="height:100%">
              <v-flex xs12 class="pb-2">
                <v-card height="100%">
                  <v-layout wrap style="height:100%" align-content-center>
                    <v-flex xs7 class="py-4 ">
                      <v-card-title class="text-xs-left">
                        Why should you move to Taiwan?
                      </v-card-title>
                      <v-card-text class="block-with-text">
                        1. The island There is no place quite like it on the planet! Taiwan was called Ilha Formosa by the Portuguese sailors when they first ...
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="primary" small flat href="https://blog.myroomabroad.com/9-reasons-why-you-should-move-to-taiwan/" target="_blank">Read more</v-btn>
                      </v-card-actions>
                    </v-flex>
                    <v-flex xs5 style="margin: auto">
                      <v-card-media cover height="auto" src="https://blog.myroomabroad.com/wp-content/uploads/2019/04/Alishan-520x400.jpg">

                      </v-card-media>
                    </v-flex>
                  </v-layout>
                </v-card>
                </v-flex>

                <v-flex xs12 class="pb-2">
                  <v-card height="100%">
                    <v-layout wrap style="height:100%" align-content-center>
                      <v-flex xs7 class="py-4 ">
                        <v-card-title class="text-xs-left">
                          Living in Taipei
                        </v-card-title>
                        <v-card-text class="block-with-text">
                          Living in Taipei can be a fantastic experience, as it is a vibrant and empowering city. This could be attributed to the city’s high level of ...
                        </v-card-text>
                        <v-card-actions>
                          <v-btn color="primary" small flat href="https://blog.myroomabroad.com/living-in-taipei/" target="_blank">Read more</v-btn>
                        </v-card-actions>
                      </v-flex>
                      <v-flex xs5 style="margin: auto">
                        <v-card-media cover height="auto" src="https://blog.myroomabroad.com/wp-content/uploads/2020/01/LIVING-IN-TAIPEI-520x400.jpg">

                        </v-card-media>
                      </v-flex>
                    </v-layout>
                  </v-card>
                  </v-flex>
                  <v-flex xs12 class="">
                    <v-card height="100%">
                      <v-layout wrap style="height:100%" align-content-center>
                        <v-flex xs7 class="py-4 ">
                          <v-card-title class="text-xs-left">
                            What is working in Taiwan like?
                          </v-card-title>
                          <v-card-text class="block-with-text">
                            Working Abroad in Taiwan can provide an ideal opportunity for you to initiate a switch in your career path or even take your first steps ...
                          </v-card-text>
                          <v-card-actions>
                            <v-btn color="primary" small flat href="https://blog.myroomabroad.com/what-is-working-in-taiwan-like/" target="_blank">Read more</v-btn>
                          </v-card-actions>
                        </v-flex>
                        <v-flex xs5 style="margin: auto">
                          <v-card-media cover height="auto" src="https://blog.myroomabroad.com/wp-content/uploads/2020/01/WHAT-IS-WORKING-IN-TAIWAN-LIKE-520x400.jpg">
                          </v-card-media>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm6 class="pa-2">
              <v-card outline height="100%">
                <v-card-title class="text-xs-center">
                  <v-spacer></v-spacer>
                  Plan your move
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-layout wrap px-3 pb-2 >
                  <v-flex xs12 class="px-2 text-xs-center">
                    <div class="pt-2">
                      <strong>Immigration information</strong>
                    </div>
                    <div class="py-1 ">
                      Find all important information for Foreigners in Taiwan on the government website:
                      <br>
                      <a href="https://www.immigration.gov.tw/5475/5478/6928/6940/">National immigration agency</a>
                    </div>
                    <div >
                      <strong>National health insurance</strong>
                    </div>
                    <div class="py-1">
                      Learn everything you need to know to apply for the NHI in Taiwan on The Ministry of Health and welfare website
                      <br>
                      <a href="https://www.nhi.gov.tw/english/Content_List.aspx?n=4A933705EEC2C868&topn=2458C2778A4C779E">Enrollment information</a>
                    </div>
                    <div >
                      <strong>Job hunting tips</strong>
                    </div>
                    <div class="py-1">
                      Ensure that your job search is successful with these practical tips.
                      <br>
                      <a href="https://blog.myroomabroad.com/how-to-find-jobs-in-taiwan/">How to find a job?</a>
                    </div>
                    <div class="pt-2">
                      <strong>Housing tips</strong>
                    </div>
                    <div class="py-1">
                      Avoid stressful and unnecessary hassle to find accommodation with these useful tips.
                      <br><a href="https://blog.myroomabroad.com/apartment-for-rental-in-taipei/">Rent in Taiwan</a>
                    </div>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-tab-item>
        <v-tab-item lazy :key="1">
          <v-layout wrap justify-center ma-3>
            <v-flex xs12>
              <h2 class="headline py-2">Your guide to <strong>STUDY</strong> in Taiwan</h2>
              <span>
                Taiwan has so much to offer to international students! It is a dynamic and welcoming country where you will have a life changing experience! This guide was made to make your move to Taiwan easier!
              </span>
            </v-flex>
          </v-layout>
          <v-layout wrap my-4>
            <v-flex xs12 sm6 class="pa-2">
              <v-card height="100%">
                <v-layout wrap style="height:100%" align-content-center>
                  <v-flex xs7 class="py-4 ">
                    <v-card-title class="text-xs-left">
                      What is it like to study in Taiwan
                    </v-card-title>
                    <v-card-text class="block-with-text">
                      Studying abroad is an exciting topic, whether it be for an exchange semester or for your entire degree. The only aspect that might be overwhelming, ...
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="primary" small flat href="https://blog.myroomabroad.com/what-is-like-to-study-in-taiwan/" target="_blank">Read more</v-btn>
                    </v-card-actions>
                  </v-flex>
                  <v-flex xs5 style="margin: auto">
                    <v-card-media cover height="auto" src="https://blog.myroomabroad.com/wp-content/uploads/2020/01/WHAT-IS-IT-LIKE-TO-STUDY-IN-TAIWAN-520x400.jpg">
                    </v-card-media>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 sm6 class="pa-2">
              <v-card height="100%">
                <v-layout wrap style="height:100%" align-content-center>
                  <v-flex xs5 style="margin: auto">
                    <v-card-media class="ma-4" cover height="auto" max-height="100%" src="https://res.cloudinary.com/my-room-abroad/image/upload/v1585282899/LP/guide.png">
                    </v-card-media>
                  </v-flex>
                  <v-flex xs7 class="py-4 ">
                    <v-card-title class="text-xs-left">
                      Getting Started in Taipei.
                    </v-card-title>
                    <v-card-subtitle>
                      Download your free Starter Guide.
                    </v-card-subtitle>
                    <v-card-actions>
                      <v-btn color="primary" small flat href="https://drive.google.com/file/d/1hP6tLwH8Wfe9cPkAyNlzNaMeH6NeQFeg/view?usp=sharing" download>DOWNLOAD</v-btn>
                    </v-card-actions>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 sm6 class="pa-2">
              <v-layout wrap style="height:100%">
              <v-flex xs12 class="pb-2">
                <v-card height="100%">
                  <v-layout wrap style="height:100%" align-content-center>
                    <v-flex xs7 class="py-4 ">
                      <v-card-title class="text-xs-left">
                        Food in Taiwan
                      </v-card-title>
                      <v-card-text class="block-with-text">
                        If you have ever looked into living in Taiwan, you have undoubtably stumbled upon praise of the local food. A common factor amongst all the ...
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="primary" small flat href="https://blog.myroomabroad.com/food-in-taiwan/" target="_blank">Read more</v-btn>
                      </v-card-actions>
                    </v-flex>
                    <v-flex xs5 style="margin: auto">
                      <v-card-media cover height="auto" src="https://blog.myroomabroad.com/wp-content/uploads/2020/01/FOOD-IN-TAIWAN-520x400.jpg">
                      </v-card-media>
                    </v-flex>
                  </v-layout>
                </v-card>
                </v-flex>
                <v-flex xs12 class="">
                  <v-card height="100%">
                    <v-layout wrap style="height:100%" align-content-center>
                      <v-flex xs7 class="py-4 ">
                        <v-card-title class="text-xs-left">
                          Taipei nightlife: The guide for the perfect party week
                        </v-card-title>
                        <v-card-text class="block-with-text">
                          Taipei is a city that never sleeps! Every day you can find something to do at night! This Taipei Nightlife Guide lays out the perfect ...
                        </v-card-text>
                        <v-card-actions>
                          <v-btn color="primary" small flat href="https://blog.myroomabroad.com/taipei-nightlife-the-guide-for-the-perfect-party-week/" target="_blank">Read more</v-btn>
                        </v-card-actions>
                      </v-flex>
                      <v-flex xs5 style="margin: auto">
                        <v-card-media cover height="auto" src="https://blog.myroomabroad.com/wp-content/uploads/2019/09/organiser-soiree-520x400.jpg">
                        </v-card-media>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm6 class="pa-2">
              <v-card outline height="100%">
                <v-card-title class="text-xs-center">
                  <v-spacer></v-spacer>
                  Plan your stay
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-layout wrap pa-3 style="text-align:center">
                  <v-flex xs12 class="px-2">
                    <div >
                      <strong>Apply to a study program</strong>
                    </div>
                    <div class="py-1">
                      Learn everything you need to know to apply for your study program in Taiwan!
                      <br>
                      <a href="https://www.studyintaiwan.org/apply">Study in Taiwan</a>
                    </div>
                    <div class="pt-2">
                      <strong>Immigration information</strong>
                    </div>
                    <div class="py-1 ">
                      Find all important information for Foreigners in Taiwan on the government website:
                      <br>
                      <a href="https://www.immigration.gov.tw/5475/5478/6928/6940/">National immigration agency</a>
                    </div>
                    <div class="pt-2">
                      <strong>Housing tips</strong>
                    </div>
                    <div class="py-1">
                      Avoid stressful and unnecessary hassle to find accommodation with these useful tips.
                      <br><a href="https://blog.myroomabroad.com/renting-a-room-in-taipei/">Rent in Taiwan</a>
                    </div>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-tab-item>
        <v-tab-item lazy :key="2">
          <v-layout wrap justify-center ma-3>
            <v-flex xs12>
              <h2 class="headline py-2">Visit Taiwan</h2>
              <span>
                Taiwan is a wonderful country with a rich culture and and abundant nature! There is so much to discover!
                <br>This guide was made to make your visit in Taiwan easier!
              </span>
            </v-flex>
          </v-layout>
          <v-layout wrap my-4>
            <v-flex xs12 sm6 class="pa-2">

              <v-card height="100%"  >

                <v-layout wrap style="height:100%" align-content-center>
                  <v-flex xs7 class="py-4 ">

                    <v-card-title class="text-xs-left">
                      What is the climate like in Taiwan?
                    </v-card-title>
                    <v-card-text class="block-with-text">
                      The climate is one of the best aspects of travelling or living in Taiwan. The most popular time to visit Taiwan is during the winter ...
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="primary" small flat href="https://blog.myroomabroad.com/what-is-the-climate-like-in-taiwan/" target="_blank">Read more</v-btn>
                    </v-card-actions>
                  </v-flex>
                  <v-flex xs5 style="margin: auto">
                    <v-card-media cover height="auto"  src="https://blog.myroomabroad.com/wp-content/uploads/2020/01/WHAT-IS-THE-CLIMATE-LIKE-IN-TAIWAN-520x400.jpg">
                    </v-card-media>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 sm6 class="pa-2">
              <v-card outline height="100%" >
                <v-card-title class="text-xs-center">
                  <v-spacer></v-spacer>
                  Plan your trip
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-layout wrap pa-3 style="text-align:left">
                  <v-flex xs6 class="px-2">
                    <div class="">
                      VISA INFORMATION
                    </div>
                    <div class="py-1">
                      <a href="https://www.immigration.gov.tw/5475/5478/6928/6940/">Immigration wesite</a>
                    </div>
                    <div class="pt-2">
                      FIND ACCOMMODATION ON:
                    </div>
                    <div class="py-1 ">
                      <a href="https://asiayo.com/en-us/">Asiayo</a>, <a href="https://www.booking.com/">booking.com</a>, <a href="https://www.airbnb.com/">Airbnb</a>
                    </div>
                  </v-flex>
                  <v-flex xs6 class="px-2">
                    <div class="">
                      GET INSPIRATION FOR TRIPS
                    </div>
                    <div class="py-1">
                      <a href="https://eng.taiwan.net.tw/">Tourism Bureau of Taiwan</a>
                      <br><a href="https://blog.myroomabroad.com/category/adventure-taiwan/">My Room Abroad blog for adventure</a>
                    </div>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 sm6 class="pa-2">
              <v-card height="100%"  >

                <v-layout wrap style="height:100%" align-content-center>
                  <v-flex xs7 class="py-4 ">

                    <v-card-title class="text-xs-left">
                      Do's and Dont's in Taiwan
                    </v-card-title>
                    <v-card-text class="block-with-text">
                      As a westerner, we do thing our own way and are not especially aware that some things we do might be offending to other culture. ...
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="primary" small flat href="https://blog.myroomabroad.com/dos-and-donts-in-taiwan/" target="_blank">Read more</v-btn>
                    </v-card-actions>
                  </v-flex>
                  <v-flex xs5 style="margin: auto">
                    <v-card-media cover height="auto"  src="https://blog.myroomabroad.com/wp-content/uploads/2018/02/rule-1752412_1920-520x400.jpg">
                    </v-card-media>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 sm6 class="pa-2">
              <v-card height="100%"  >

                <v-layout wrap style="height:100%" align-content-center>
                  <v-flex xs7 class="py-4 ">

                    <v-card-title class="text-xs-left">
                      10 things to do before leaving Taiwan
                    </v-card-title>
                    <v-card-text class="block-with-text">
                      When abroad for a few months it is always difficult to choose what to do, what to visit. There is no time to visit every ...
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="primary" small flat href="https://blog.myroomabroad.com/10-things-must-leaving-taiwan/" target="_blank">Read more</v-btn>
                    </v-card-actions>
                  </v-flex>
                  <v-flex xs5 style="margin: auto">
                    <v-card-media cover height="auto"  src="https://blog.myroomabroad.com/wp-content/uploads/2018/03/Kenting-520x400.jpg">
                    </v-card-media>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-tab-item>
      </v-tabs-items>
    </v-flex>
  </v-layout>
  <v-layout class="py-3" justify-center wrap>
    <v-flex xs12 sm10 md8>
      <v-layout wrap justify-center>

        <v-flex justify-center xs12 class="px-3">
          <h2 class="justify-center headline">
            Activities in Taiwan
          </h2>
        </v-flex>
        <v-flex xs12 sm4 class="pa-3" v-for="(ev,index) in events" :key="index">
          <v-card href="/events" height="100%" hover>
            <v-img cover aspect-ratio="1.78" :src="'https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto/v1565684569/'+ev.img[0].id">
            </v-img>
            <v-card-title style="text-align:left">
              {{ev.title}}
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>


  <!-- </v-container>
<v-container grid-list-xl> -->
  <v-layout class="py-3" justify-center wrap>
    <v-flex xs12 sm10 md8>
      <v-layout wrap justify-center>

        <v-flex justify-center xs12 class="px-3">
          <h2 class="justify-center headline">
            Discover Taiwan's best kept secrets
          </h2>
        </v-flex>
        <v-flex xs12 pa-3 sm4 justify-center>
          <v-card hover href="https://blog.myroomabroad.com/category/adventure-taiwan/" target="_blank">
            <v-img cover aspect-ratio="1.78" src="https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto/v1567407535/homepage/Jiufen-teapot.png">
            </v-img>
            <v-card-title primary-title>
              Adventure in Taiwan
            </v-card-title>
          </v-card>
        </v-flex>
        <v-flex xs12 sm4 class="pa-3">
          <v-card hover href="https://blog.myroomabroad.com/category/life-in-taipei/" target="_blank">
            <v-img cover aspect-ratio="1.78" src="https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto/v1567407535/homepage/rainy-day-taipei.jpg">
            </v-img>
            <v-card-title primary-title>
              Life in Taipei
            </v-card-title>
          </v-card>
        </v-flex>
        <v-flex xs12 sm4 class="pa-3">

          <v-card hover href="https://blog.myroomabroad.com/category/about-mra/" target="_blank">
            <v-img cover aspect-ratio="1.78" src="https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto/v1567407535/homepage/MRA_TEAM.jpg">
            </v-img>
            <v-card-title primary-title>
              About My Room Abroad
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>

</div>
</template>
<script>
import Navbar from '../components/LPNavbarWhite.vue'
import NavbarTransp from '../components/LPNavbarTransparent.vue'

export default {
  name: "",
  data: () => ({
    tab: 0,
    events: [{
        title: "Hiking Jade Mountain",
        img: [{
          id: "events/xx7xsrhc4knew0jpctro"
        }],

      },
      {
        title: "Meet with the Taroko indigenous tribe",
        img: [{
          id: "events/kzeieh7xirkrgn7wniao"
        }]
      },
      {
        title: "Snorkel with turtles",
        img: [{
          id: "events/wtunmamvvarxevci7vgt"
        }]
      }
    ]
  }),
  created() {
    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })

  },
  components: {
    Navbar,
    NavbarTransp
  }
}
</script>
<style lang="scss" scoped>
.search-row {
    background: rgba(255,255,255,0),;
    background: rgba(102,102,102,0.5), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,w_1400/v1537779176/LP/LP2.jpg") center/cover no-repeat;
    background: -moz-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/LP/LP2.jpg") center/cover no-repeat;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(102,102,102,0.5)), color-stop(40%, rgba(133,133,133,0)), color-stop(100%, rgba(255,255,255,0))), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/LP/LP2.jpg") center/cover no-repeat;
    background: -webkit-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/LP/LP2.jpg") center/cover no-repeat;
    background: -o-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/LP/LP2.jpg") center/cover no-repeat;
    background: -ms-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/LP/LP2.jpg") center/cover no-repeat;
    background: linear-gradient(to bottom, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/LP/LP2.jpg") center/cover no-repeat;
}
.blog-title {
    padding-top: 15vh;
    margin-bottom: 10vh;
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0,0,0,.6);
}
.block-with-text {
  display: -webkit-box;
  max-width: 100%;
  height: 3.6em; /* Fallback for non-webkit */
  line-height: 1.3em;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.justify {
    text-align: justify;
}
.nomargin {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
</style>
