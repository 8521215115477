<template>
  <div id="">
    <v-layout wrap pa-2>
      <v-flex xs12 class="px-2">
        <h2>Some of the best apartments in Taipei</h2>
      </v-flex>
      <v-flex xs12 class="px-2">
        We have listed over 2000 of the best apartments available in Taipei.
      </v-flex>
      <v-flex >
        <div class="noScrollbar" style="max-width:95vw; overflow-x: scroll;">
          <listingCards  :listings="rooms"></ListingCards>
        </div>
        <v-layout pa-3>
          <a color="primary" class="subheading"  @click="showAllClick()" :href="url">Show All (2000+)</a>
        </v-layout>

      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import ListingCards from './ListingCards.vue'
export default {
  name: "",
  data: () => ({
    url:'/aptSearch?propType=wholeApts',
    rooms:[
      {
        url:'/studioDetails/-LhiiKAPP_NSJSzwu-6_/',
        img:"-Lhii8L4OCPfmhHHLyLE/gx7voajrdfjnq777yeao",
        title:'Clean One-Bedroom Apartment Near Zhongxiao Dunhua',
        price:"TW$ 32000/Month",
        area:"Xinyi",
        nextAv:"March 1st"
      },{
        url:'/apartmentDetails/-LQ7Zttpj4MSYG0kxtyv/apartment/',
        img:"-LOqEaHmz54W80lAcNlK/hbh1ndzyzhybma9mjvkd",
        title:'Fancy four-bedroom apartment in central Taipei',
        price:"TW$ 60000/Month",
        area:"Xinyi",
        nextAv:"March 1st"
      },{
        url:'/apartmentDetails/-Lmd2bkT2d3t7N8pMhth/apartment/',
        img:"-LfqrFaK9uxRisxLq-Tv/tvqmatjym2zn4tvamxn9",
        title:'Beautiful apartment nearby Housanpi MRT Station',
        price:"TW$ 58000/Month",
        area:"Housanpi",
        nextAv:"March 1st"
      },{
        url:'/apartmentDetails/-LxeuaO3ueObPveu8UYs/apartment',
        img:"-LfqrFaK9uxRisxLq-Tv/ait2hk4tdgsaf7ubn4ss",
        title:'[Short term] Apartment nearby Taipower Building MRT',
        price:"TW$ 42560/Month",
        area:"Taipower Building",
        nextAv:"March 1st"
      },{
        url:'/apartmentDetails/-M-SeOoMVSoR83ZF7mxt/apartment',
        img:"-M-SdwHaet1l9J8dHBuc/iq62wsb2mwmfbuzn5ps5",
        title:'1 min to MRT Dapinglin Station/Elevator Apartment',
        price:"TW$ 46000/Month",
        area:"Dapinglin",
        nextAv:"March 1st"
      },{
        url:'/apartmentDetails/-LzbTR1TowuCM8RbM3OL/apartment',
        img:"-LadCciJVw5eu48L33Kv/rjiryhfhethb2vm82fd1",
        title:"Very central 4 bedroom Apartment in Xiny Anhe",
        price:"TW$ 40500/Month",
        area:"Xinyi",
        nextAv:"March 1st"
      },{
        url:'/apartmentDetails/-LX1yQ6qRtr4acMI3Vgb/apartment/',
        img:"-LOqmuL1fdmuyvZRaYZj/bazm76ncs8xojwfkacta",
        title:'3-Bedroom apartment close to MRT Taipower Building',
        price:"TW$ 40000/Month",
        area:"Taipower Building",
        nextAv:"March 1st"
      },{
        url:'/apartmentDetails/-LX1yQ6qRtr4acMI3Vgb/apartment/',
        img:"-LSTBDdvd2kH_lhBatad/ijrnqejbblo0cebwub03",
        title:'3-Bedroom Apartment close to Xinyi commercial center',
        price:"TW$ 35000/Month",
        area:"Xinyi",
        nextAv:"March 1st"
      }
    ]
  }),
  methods: {
    showAllClick() {
      dataLayer.push({
        'event':'showAllClicked'
      })
    }
  },
  components: {
    ListingCards
  }
}
</script>
<style lang="scss" scoped>
.noScrollbar::-webkit-scrollbar {
  display: none;
}
.noScrollbar{
  -ms-overflow-style:none;
}

</style>
