<template>
  <div id="">
    <v-layout wrap pa-2>
      <v-flex xs12 class="px-2">
        <h2>What do tenants say about the apartments in Taipei</h2>
      </v-flex>
      <v-flex xs12 class="px-2">
        Here are the latest reviewed apartments on our platform.
      </v-flex>
      <v-flex >
        <div class="noScrollbar" style="max-width:95vw; overflow-x: scroll;">
          <ReviewCards  :listings="rooms"></ReviewCards>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import ReviewCards from './ReviewCards.vue'
export default {
  name: "",
  data: () => ({
    rooms:[
      {
        url:'/apartmentDetails/-LQbdOmq5QrTdne_RbrN/apartment/',
        img:"-LOqEaHmz54W80lAcNlK/ne7z9fmhq3uxixek5qox",
        stars:5,
        comment:'We rented this apartment together with 4 friends! We had a great time!  The apartment was good and the My Room Abroad team was really helful to help us commmunicate with the landlord when we had questions!',
        revName:"Pol",
        revPhoto:"reviewers/Pol",
        revLoc:"Barcelona, Spain",
      },
      {
        url:'/apartmentDetails/-LfxWfkhu9lFNgs1OUBB/rooms/',
        img:"-LVbNnvzMFf2fOojbxl_/x4irc7ijna7nujmluqdy",
        stars:5,
        comment:"Lovely place, lovely neighbors, lovely customer service by My Room Abroad and the landlord. This website helped me a lot to find a perfect place for me to live in Taipei and I'm so grateful for that.",
        revName:"Lynda",
        revPhoto:"reviewers/Lynda",
        revLoc:"PA, United States",
      },
      {
        url:'/apartmentDetails/-LOqKLdKPdxtgxG5fpUS/rooms/',
        img:"-LOqFfd0QOKVbDjOMKUq/pcxf9gvxzp5ubff0maqz",
        stars:5,
        comment:"Great apartment. Clean and as expected from the pictures on the website!! The landlord was always there when needed with the help of My Room Abroad to translate our requests! ",
        revName:"Lynn",
        revPhoto:"reviewers/Lynn",
        revLoc:"Cape Town, South Africa",
      },
      {
        url:'/apartmentDetails/-Lfs0KpfdRaQhE0EmjGw/rooms/',
        img:"-Lfs-XAhAZbcQgXNbekF/c7dlaldoulvxshwribto",
        stars:5,
        comment:'Loved the place! Great location! Really convenient and dynamic neibourhood!',
        revName:"Ludovic",
        revPhoto:"reviewers/Ludovic",
        revLoc:"Paris, France",
      },
      {
        url:'/details/-LwpluVZ9KcazeRiqRsW',
        img:"-LfXbyi4E5HfERRvQ9JQ/zymtum1gpphotwsguzxi",
        stars:5,
        comment:'A great place to stay at! Convenient, practical, and well equipped. The landlady is very responsive, responsible and treats his tenants with great respect and attention. She even left us some typical Taiwanese snacks! Place is comfortable and spacious, we were not disappointed!',
        revName:"Joshua",
        revPhoto:"reviewers/Joshua",
        revLoc:"Sydney, Australia",
      },
      {
        url:'/details/-LQODg8O1Qnc-nD0mqva/',
        img:"-LOu1ubbI4O3cvMSox2o/lrn76trynwjqi5uzrbgz",
        stars:5,
        comment:'Fantastic location, especially for any university related things, and living close from a great restaurant and night market makes this a super good Taipei experience!',
        revName:"Ruben",
        revPhoto:"reviewers/Ruben",
        revLoc:"Netherlands",
      },
    ]
  }),

  components: {
    ReviewCards
  }
}
</script>
<style lang="scss" scoped>
.noScrollbar::-webkit-scrollbar {
  display: none;
}
.noScrollbar{
  -ms-overflow-style:none;
}

</style>
